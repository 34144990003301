import { IFilter } from "../../../../shared/interface/filter";
import { BaseService } from "../../../../shared/services/base.service";
import { IFarmDto } from "../dtos/farm.dto";

class AppUsersService extends BaseService<IFarmDto, Partial<IFilter>> {
  private path = "AppUsers";
  private get userId() {
    return localStorage["user_id"]; //TODO: should be moved to a separate state or to another storage with async access
  }

  // eslint-disable-next-line
  list(filter: Partial<IFilter>, include: any): Promise<IFarmDto[]> {
    throw new Error("Method not implemented.");
  }

  get(_: string): Promise<IFarmDto> {
    throw new Error("Method not implemented.");
  }

  async availableFarmsList(): Promise<IFarmDto[]> {
    return this.fetch<IFarmDto[]>([this.path, this.userId, "farms"].join("/"), {
      method: "GET",
      searchParams: {
        filter: {},
      },
    });
  }

  async getDefaultFarmName(): Promise<string> {
    const defaultFarm = await this.fetch<IFarmDto>(
      [this.path, this.userId, "defaultFarm"].join("/"),
      {
        method: "GET",
      }
    );

    return defaultFarm.name;
  }
}

export const appUsersService = new AppUsersService();
