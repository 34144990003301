import { TAssetUsageRecordsConfigurationState } from "../interfaces/asset-usage-records-state";
import { assetUsageRecordColumnLabels } from "./asset-usage-record-column-labels";
import { ColumnName } from "../enums/column-name";

export function getConfigurationState(): TAssetUsageRecordsConfigurationState {
  return {
    columns: assetUsageRecordColumnLabels,
    // initially visible columns (if one of them isn't presended in configurable array, then it will be persistent)
    selected: Object.values(ColumnName),
    configurable: [
      ColumnName.PersonCreatedName,
      ColumnName.PersonResponsibleName,
      ColumnName.Norma,
      ColumnName.CropTypeName,
      ColumnName.Comment,
    ],
  };
}
