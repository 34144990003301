import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IFertilisersDto, IFertilisersState, fertilisersService } from "../shared";
import { Fertiliser } from "../shared/models/fertiliser";
import { FERTILISERS_MODULE_NAME } from "./constants";

const initialState: IFertilisersState = {
  isLoading: false,
  list: [],
  selectedIds: [],
  searchText: "",
};
export const fertilisersSlice = createSlice({
  name: FERTILISERS_MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<Fertiliser[]>): void {
      state.list = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFertilisersAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchFertilisersAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchFertilisersAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const fertilisersReducer = fertilisersSlice.reducer;
export const { setListAction, setSelectedIdsAction, toggleId, setSearchText } =
  fertilisersSlice.actions;

export const fetchFertilisersAction = createAsyncThunk(
  `${FERTILISERS_MODULE_NAME}/fetchFertilisers`,
  async (params, { dispatch }) => {
    const dtos: IFertilisersDto[] = await fertilisersService.list({});
    const models: Fertiliser[] = dtos
      .filter((dto) => Boolean(dto.isDeleted) === false) // 3355
      .map((dto) => {
        const model = new Fertiliser(dto.id);
        model.updateFromDto(dto);
        return model;
      });
    models.sort((a: Fertiliser, b: Fertiliser) => a.name.localeCompare(b.name));
    dispatch(setListAction(models));
  }
);

export const deleteFertilisersAction = createAsyncThunk<void, string[]>(
  `${FERTILISERS_MODULE_NAME}/deleteFertilisersAction`,
  async (ids, { rejectWithValue }) => {
    const selectedToDeletePromises = ids.map((id) => fertilisersService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
