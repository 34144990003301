import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Phenophase } from "../shared/models/phenophases";
import { IPhenophasesState } from "../shared/interfaces/phenophases-state";
import { IPhenophaseDto } from "../shared/dtos/phenophases";
import { phenophasesService } from "../shared/services/phenophases.service";
import { PHENOPHASES_MODULE_NAME } from "./constants";

const initialState: IPhenophasesState = {
  isLoading: false,
  list: [],
  selectedIds: [],
  searchText: "",
};
export const phenophasesSlice = createSlice({
  name: PHENOPHASES_MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<Phenophase[]>): void {
      const phenophases = action.payload;
      phenophases.sort((a, b) => a.number - b.number);
      state.list = phenophases;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPhenophasesAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchPhenophasesAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchPhenophasesAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const phenophasesReducer = phenophasesSlice.reducer;
export const { setListAction, setSelectedIdsAction, toggleId, setSearchText } =
  phenophasesSlice.actions;

export const fetchPhenophasesAction = createAsyncThunk(
  `${PHENOPHASES_MODULE_NAME}/fetchPhenophases`,
  async (params, { dispatch }) => {
    const dtos: IPhenophaseDto[] = await phenophasesService.list({});
    const models = dtos
      .map((dto: IPhenophaseDto) => {
        const model = new Phenophase(dto.id);
        model.updateFromDto(dto);
        return model;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    dispatch(setListAction(models));
  }
);

export const deletePhenophasesAction = createAsyncThunk<void, string[]>(
  `${PHENOPHASES_MODULE_NAME}/deleteCropsAction`,
  async (ids, { rejectWithValue }) => {
    const selectedToDeletePromises = ids.map((id) => phenophasesService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
