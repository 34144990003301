import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../../../shared/utils/rtkq";
import { toQueryString } from "src/shared/utils/auth-token";
import { IAssetStocks } from "../types";

export const ASSETS_STOCKS_MODULE_NAME = "assetStocks";

export const assetStocksSlice = createApi({
  reducerPath: "assetStocks",
  tagTypes: ["AssetStocks"],
  baseQuery,
  endpoints: (builder) => ({
    fetchAllStocks: builder.query<IAssetStocks[], string>({
      query: (farmId) => ({
        url: `/AssetStocks?${toQueryString({}, true)}`,
        method: "GET",
        params: {
          farmId,
        },
      }),
    }),
  }),
});

export const { useFetchAllStocksQuery } = assetStocksSlice;
