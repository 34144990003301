import { BaseService } from "../../../../shared/services";
import { IAssetUsageRecordDto } from "../dtos/asset-usage-record.dto";
import { TAssetUsageFilter } from "../interfaces/asset-usage-filter";
import { TEkoCropApiFilter } from "../../../../modules/filter/shared/utils/eko-crop-filter-adapter";
import { AssetUsageRecordStatus } from "../enums/asset-usage-record-status";

type TFilter = TEkoCropApiFilter<TAssetUsageFilter>;
class AssetUsageRecordsService extends BaseService<IAssetUsageRecordDto, TFilter> {
  private path = "AssetUsageRecords";

  async get(id: string): Promise<IAssetUsageRecordDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }
  async list(
    filter: TFilter,
    options: { signal?: AbortSignal }
  ): Promise<IAssetUsageRecordDto[]> {
    const { signal = null } = options;
    return this.fetch<IAssetUsageRecordDto[]>(this.path, {
      method: "GET",
      searchParams: { ...filter },
      signal,
    });
  }

  async listCount(filter: TFilter, options: { signal?: AbortSignal }): Promise<number> {
    const { signal = null } = options;
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: { ...filter },
      signal,
    });
  }

  async update(dto: IAssetUsageRecordDto): Promise<IAssetUsageRecordDto> {
    if (!dto.id) {
      throw Error("Try to update an invalid asset usage record");
    }

    return this.fetch([this.path, dto.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(dto),
    });
  }

  async prepareToSend(ids: string[]): Promise<IAssetUsageRecordDto[]> {
    const { data, docsDownloadLink } = await this.fetch<{
      data: IAssetUsageRecordDto[];
      docsDownloadLink: string;
    }>(this.path, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify({
        data: ids.map((id) => ({ id, status: AssetUsageRecordStatus.PreparedToSend })),
      }),
    });

    await this.downloadFileAsync(docsDownloadLink);

    return data;
  }
}

export const assetUsageRecordsService = new AssetUsageRecordsService();
