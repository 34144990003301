import { stringify } from "qs";

import { ArcGisFormat, ArcGisGeometryType, ArcGisSpatialRel, IArcGisFilter } from "../interface/arcgis-filter";

export abstract class BaseArcgisService {
  public readonly ROOT_URL: string = process.env.REACT_APP_ARCGIS_REST_SERVICE_URL || "";
  protected abstract path: string;
  private static token: string;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private static postponedResolves: Array<(value?: any) => void> = [];

  async list<TResponse>(filter: IArcGisFilter): Promise<TResponse> {
    const url = this.constructUri(filter);
    if (BaseArcgisService.token) {
      return fetch(url).then(this.handleResponse);
    } else {
      return new Promise<TResponse>((resolve) => {
        BaseArcgisService.postponedResolves.push(resolve);
      }).then(() => fetch(url).then(this.handleResponse));
    }
  }

  setToken(token: string): void {
    BaseArcgisService.token = token;
    this.callPostponedResolves();
  }

  getToken(): string {
    return BaseArcgisService.token;
  }

  private constructUri(filter: IArcGisFilter): string {
    return [this.ROOT_URL, this.path, this.stringifyFilter(filter)].join("/");
  }

  private callPostponedResolves() {
    while (BaseArcgisService.postponedResolves.length) {
      const resolve = BaseArcgisService.postponedResolves.pop();
      if (resolve) resolve();
    }
  }

  private stringifyFilter(filter: IArcGisFilter) {
    const filterString = stringify(
      Object.assign(
        {
          where: "1=1",
          objectIds: [],
          geometryType: ArcGisGeometryType.Envelope,
          spatialRel: ArcGisSpatialRel.Intersects,
          outFields: "",
          returnGeometry: false,
          returnIdsOnly: false,
          returnCountOnly: false,
          orderByFields: [],
          returnDistinctValues: false,
          f: ArcGisFormat.Json, // TODO: change default to geoJSON
          // geometry: undefined,
          // resultRecordCount: undefined,
        },
        filter
      )
    );

    return `query?${filterString}&token=${BaseArcgisService.token}`;
  }

  private handleResponse(response: Response) {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    // ArcGisError here => WAIT_UNTIL_FarmLandCropsGeoService_ABSORBS_ARCGIS_TOKEN
    return response.json();
  }
}
