import { createSelector } from "reselect";

import { INFO_DATA_KEY } from "./info-data-constants";
import { InfoDataState } from "./info-data-types";

// https://stackoverflow.com/questions/66553130/how-to-use-createselector-with-parameter-and-typescript
export type Selector<S> = (state: unknown) => S;

// eslint-disable-next-line
export const getStateInfoData = (appState: any) => appState[INFO_DATA_KEY];

export const getInfoDepartments = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.departments
);

export const getInfoFarms = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.farms
);
export const getSortedInfoFarms = createSelector(
  getStateInfoData,
  (state: InfoDataState) => {
    const sortedItems = state.farms.concat();
    sortedItems.sort((a, b) => (a.name > b.name ? 1 : -1));
    return sortedItems;
  }
);
export const getInfoPositions = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.positions
);

export const getInfoSeasons = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.seasons
);

export const getInfoWorkPlace = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.workPlace
);

export const getInfoTechTypes = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techTypes
);

export const getInfoTechOperationGroups = createSelector(
  getStateInfoData,
  (state: InfoDataState) => {
    // if (!state) {
    //   // TODO: fix "Uncaught TypeError: Cannot read properties of undefined (reading 'techOperationGroup'"
    //   // happens when I click on new "?" icon in a row with tech operation
    //   return [];
    // }
    return state.techOperationGroups;
  }
);

export const getInfoTechOperationSubGroups = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techOperationSubGroups
);

export const getTechAssetCategories = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techAssetCategory
);

export const getTechOperationsScalars = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.techOperationsScalars
);

export const getInitialDictionariesLoaded = createSelector(
  getStateInfoData,
  (state: InfoDataState) => state.initialDictionariesLoaded
);
