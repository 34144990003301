import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "../../../../store";
import { getInfoSeasons } from "../../../info-data";
import { ISeason } from "../../../info-data/shared/interfaces/season";
import { getFilter, getInit } from "../../store/filters.selector";
import { setFilterAction } from "../../store/filters.slice";
import { FilterName } from "../enums/filter-name";

export const useSeasonEffect = (): void => {
  const dispatch = useAppDispatch();
  const isFilterInit = useSelector(getInit);
  const seasons = useSelector(getInfoSeasons);
  const SeasonId = useSelector(getFilter(FilterName.SeasonId));

  const currentSeasonId: string | null = useMemo(() => {
    const currentSeason = seasons?.find((item) => item.isCurrent);
    if (currentSeason && currentSeason.id) {
      return currentSeason.id;
    }
    if (seasons && seasons.length > 0) {
      return seasons[0].id;
    }
    return null;
  }, [seasons]);

  useEffect(() => {
    if (!seasons || seasons.length === 0 || !isFilterInit) {
      return;
    }

    if (SeasonId === null) {
      dispatch(setFilterAction({ filter: FilterName.SeasonId, value: currentSeasonId }));
    } else {
      // update season name if seasonId has been changed
      const season = (seasons.find((item) => item.id === SeasonId) as ISeason).name;
      dispatch(setFilterAction({ filter: FilterName.Season, value: season }));
    }
    // eslint-disable-next-line
  }, [seasons, SeasonId, currentSeasonId, isFilterInit]);
};
