import { IFilter } from "../interface/filter";

export type TResultPaginationFilter = Partial<{
  limit: number;
  offset: number;
  order: string[];
}>;

export function normalizeFilter(filter: Partial<IFilter>): TResultPaginationFilter {
  const { order, limit, offset } = filter;
  const result: TResultPaginationFilter = { limit, offset };

  if (order) {
    result.order = order.map((item) => item.join(" "));
  }

  return result;
}
