import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as getId } from "uuid";

import { NotificationSeverity } from "../shared/enums/notification-severity";
import { NotificationType } from "../shared/enums/notification-type";
import { INotification } from "../shared/interfaces/notification";
import { INotificationsState } from "../shared/interfaces/notifications-state";
import { NOTIFICATIONS_MODULE_NAME } from "./constants";

const initialState: INotificationsState = {
  systemNotifications: [],
};

const defaultNotification: Omit<INotification, "id"> = {
  type: NotificationType.Alert,
  message: "",
  title: "",
  severity: NotificationSeverity.Info,
  cb: () => void 0,
  error: null,
};

export const notificationsSlice = createSlice({
  name: NOTIFICATIONS_MODULE_NAME,
  initialState,
  reducers: {
    addNotificationAction(state, action: PayloadAction<Partial<INotification>>): void {
      state.systemNotifications.push({
        id: getId(),
        ...defaultNotification,
        ...action.payload,
      });
    },
    removeNotificationAction(state, action: PayloadAction<INotification>): void {
      const index = state.systemNotifications.findIndex(
        (item) => action.payload.id === item.id
      );
      if (index === -1) {
        return;
      }
      state.systemNotifications.splice(index, 1);
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;

export const { addNotificationAction, removeNotificationAction } =
  notificationsSlice.actions;
