import {
  ImportContacts as ImportContactsIcon,
  Map as MapIcon,
  NaturePeople as NaturePeopleIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

import { TractorIcon } from "../../../../shared/components/icons";
import { AssetUsageRecordsIcon } from "../../../../shared/components/icons/asset-usage-records-icon";
import { IconsEnum } from "../../types";
import { colorWhite } from "../../styles";

export const SideBarIcon = ({ icon }: { icon: IconsEnum }): JSX.Element => {
  switch (icon) {
    case IconsEnum.MapIcon:
      return <MapIcon key={IconsEnum.MapIcon} sx={colorWhite} />;

    case IconsEnum.TractorIcon:
      return <TractorIcon key={IconsEnum.TractorIcon} sx={colorWhite} />;

    case IconsEnum.AssetUsageRecordsIcon:
      return (
        <AssetUsageRecordsIcon key={IconsEnum.AssetUsageRecordsIcon} sx={colorWhite} />
      );

    case IconsEnum.NaturePeopleIcon:
      return <NaturePeopleIcon key={IconsEnum.NaturePeopleIcon} sx={colorWhite} />;

    case IconsEnum.ImportContactsIcon:
      return <ImportContactsIcon key={IconsEnum.ImportContactsIcon} sx={colorWhite} />;

    case IconsEnum.SettingsIcon:
      return <SettingsIcon key={IconsEnum.SettingsIcon} sx={colorWhite} />;

    default:
      return <></>;
  }
};
