import { useEffect, useState } from "react";

export const useAppbarHeight = (defaultHeight = 64): { appbarHeight: number } => {
  const [appbarHeight, setAppbarHeight] = useState<number>(defaultHeight);

  useEffect(() => {
    const appBar = document.querySelector("header.MuiAppBar-root");
    setAppbarHeight(appBar?.clientHeight || defaultHeight);

    function handleResize() {
      setAppbarHeight(appBar?.clientHeight || defaultHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, []); // empty array => set browser window resize listener on FIRST PAGE LOAD

  return { appbarHeight };
};
