import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IAppUsersState } from "../shared";
import { RootState } from "../../../store-types";
import { APP_USERS_MODULE_NAME } from "./constants";

export const getAppUsersState = (rootState: RootState): IAppUsersState =>
  rootState[APP_USERS_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getAppUsersState,
  (state: IAppUsersState) => state.isLoading
);
export const getAppUserList = createDraftSafeSelector(
  getAppUsersState,
  (state: IAppUsersState) => state.list
);
export const getSelectedIds = createDraftSafeSelector(
  getAppUsersState,
  (state: IAppUsersState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getAppUsersState,
  (state: IAppUsersState) => state.searchText
);
