import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { devlog } from "../../../shared/utils/log";
import { toQueryString } from "../../../shared/utils/auth-token";
import { OrderGroup } from "../../../shared/interface";
import { loopBackFilterAdapter } from "../../../modules/filter/shared/utils/loopback-filter-adapter";
import escapeStringRegexp from "escape-string-regexp";
import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { IFarmLandDto } from "../../../pages/fields/shared/dtos/farm-land.dto";
import { FarmLand } from "../../../pages/fields/shared/models/farm-land";

export type FetchFarmLandsRequest = {
  limit: number;
  order: OrderGroup;
  offset: number;
  area: number | undefined;
  name: string;
  createdAt: Date | null;
};

export const farmLandsApiSlice = createApi({
  reducerPath: "api-farm-lands",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl:
        process.env.REACT_APP_API_ENDPOINT || ".env: define REACT_APP_API_ENDPOINT",
    }),
    { maxRetries: 1 }
  ),
  endpoints: (builder) => ({
    fetchFarmLand: builder.query<FarmLand, string>({
      query: (id: string) => {
        devlog(`ex-fetchFarmLandByIdAction(${id}):`);
        const qs = toQueryString();
        const relPath = `FarmLands/${id}?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dto: IFarmLandDto) => {
        const model = new FarmLand();
        model.updateFromDto(dto);
        return model;
      },
    }),

    fetchFarmLandIdByArcGisId: builder.query<string | undefined, string>({
      query: (id: string) => {
        devlog(`ex-fetchFarmLandIdByArcGisIdAction(${id})`);
        const qs = toQueryString({
          filter: {
            where: {
              arcgisObjectId: id,
            },
          },
        });
        const relPath = `FarmLands?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dtos: IFarmLandDto[]) => {
        const ret = dtos[0]?.id;
        return ret; // INSERT_BREAKPOINT
      },
    }),

    fetchFarmLands: builder.query<FarmLand[], FetchFarmLandsRequest>({
      query: (searchParams) => {
        devlog(`ex-fetchFarmLandsAction():`, searchParams);
        const qs = toListQueryString(searchParams);
        const relPath = `FarmLands?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dtos: IFarmLandDto[]) => {
        const models = dtos.map((dto) => {
          const model = new FarmLand();
          model.updateFromDto(dto);
          return model;
        });
        return models;
      },
    }),
    fetchFarmLandsCount: builder.query<number, FetchFarmLandsRequest>({
      query: (searchParams) => {
        devlog(`ex-fetchFarmLandsCountAction():`, searchParams);
        const qs = toListQueryString(searchParams);
        const relPath = `FarmLands/count?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dto: { count: number }) => {
        return dto.count;
      },
    }),
  }),
});

function toListQueryString(searchParams: FetchFarmLandsRequest) {
  const { limit, order, offset, area, name, createdAt } = searchParams;
  const filter: ILoopbackFilter = loopBackFilterAdapter(
    {
      limit,
      order,
      offset,
      area,
      name: name && `%${escapeStringRegexp(name)}%`,
      createdAt: createdAt && createdAt.getTime(),
    },
    {
      where: {
        or: [{ name: "like" }, { area: "gte" }, { createdAt: "gte" }],
      },
      optional: ["name", "area", "createdAt"],
    }
  );
  const qs = toQueryString({
    filter: filter,
  });
  return qs;
}

export const {
  useLazyFetchFarmLandQuery,
  useFetchFarmLandIdByArcGisIdQuery,
  useLazyFetchFarmLandIdByArcGisIdQuery,
  useFetchFarmLandsQuery,
  useFetchFarmLandsCountQuery,
} = farmLandsApiSlice;
