const modulesApiName = "ekocropApi";
const apiObject = {};

export function useModuleApi<T>(name: string, moduleApi: T): T {
  const globalContext = window || {};

  if (!Object.prototype.hasOwnProperty.call(globalContext, modulesApiName)) {
    globalContext[modulesApiName] = apiObject;
    console.log(
      `Ekocrop console API is available by key "${modulesApiName}" in global context (window)`
    );
  }

  if (typeof globalContext[modulesApiName] !== "object") {
    throw new Error("Api object could not be extended");
  }

  if (!Object.prototype.hasOwnProperty.call(apiObject, name)) {
    apiObject[name] = moduleApi;
  }

  return moduleApi;
}
