import { TLabel } from "../../../../shared/interface";
import { IPestDto } from "../dtos/pests";

export class Pest {
  readonly id: string;
  name: string;
  latinName: string;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IPestDto {
    return {
      id: this.id,
      name: this.name,
      latinName: this.latinName,
    };
  }

  updateFromDto(dto: IPestDto): void {
    this.name = dto.name;
    this.latinName = dto.latinName;
  }

  get asViolationType(): TLabel {
    return {
      id: this.id,
      title: this.name,
    };
  }
}
