const nonProdHosts = [
  "develop.ekocrop.ekoniva-apk.dev",
  "release.ekocrop.ekoniva-apk.dev",
  "localhost" // test me here
];

export function trueForDev(force?: boolean): boolean {
  // const env: string | undefined = process.env.NODE_ENV;
  // // eslint-disable-next-line no-console
  // console.error(`process.env.NODE_ENV[${env}]`, process.env);
  // const trueWhenForceOmitted = nonProdEnvs.includes(env || "");

  const myHost = window.location.hostname;
  const trueWhenForceOmitted = nonProdHosts.includes(myHost);

  if (force !== undefined) {
    return force;
  }

  return trueWhenForceOmitted;
}

// eslint-disable-next-line
export function devlog(message?: any, ...optionalParams: any[]): void {
  if (trueForDev()) {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams);
  }
}

// eslint-disable-next-line
export function devwarn(message?: any, ...optionalParams: any[]): void {
  if (trueForDev()) {
    // eslint-disable-next-line no-console
    console.warn(message, ...optionalParams);
  }
}

// eslint-disable-next-line
export function deverror(message?: any, ...optionalParams: any[]): void {
  if (trueForDev()) {
    // eslint-disable-next-line no-console
    console.error(message, ...optionalParams);
  }
}
