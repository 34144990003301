import {
  AnyObject,
  ILoopbackFilter,
  ILoopbackFilterSchema,
  Where,
  WhereSchema,
} from "../interfaces/loopback";

function parseWhere<MT extends AnyObject>(
  src: MT,
  whereSchema: WhereSchema<MT>
): Where<MT> {
  const where: Where<MT> = {};

  Object.entries(whereSchema).forEach(([key, value]) => {
    if (["or", "and"].includes(key)) {
      if (!Array.isArray(value)) {
        throw new Error("or/and values must be an array");
      }

      where[key] = value.map((item) => parseWhere<MT>(src, item));
      return;
    }

    let condition = "eq";
    if (Array.isArray(value)) {
      condition = value[0];
      value = value[1];
    } else {
      condition = value;
      value = src[key];
    }

    if ([null, undefined, ""].includes(value)) {
      return;
    }

    where[key] = { [condition]: value, options: "i" };
  });

  return where;
}

// eslint-disable-next-line
export function loopBackFilterAdapter<TSrc extends Record<string, any>>(
  src: TSrc,
  schema: ILoopbackFilterSchema<TSrc>
): ILoopbackFilter<TSrc> {
  const filter: ILoopbackFilter<TSrc> = {};
  const { where, fields, include, optional = [] } = schema;

  for (const key in src) {
    const value = src[key];
    const failOnValues = [null, undefined, ""];
    const isInvalid = failOnValues.includes(value);
    const isRequired = !optional.includes(key);
    if (isInvalid && isRequired) {
      // return {}; // failed to convert (fields + schema + optional) => filter
      // eslint-disable-next-line no-console
      console.error(
        `CRITICAL: key[${key}] isRequired but value[${value}] isInvalid; skipping`
      );
      continue;
    }
  }

  // TODO: refactor to work with such kind of keys
  if (include) filter.include = include;
  if (fields) filter.fields = fields;
  if (src["limit"]) filter.limit = src["limit"];
  if (src["offset"]) filter.offset = src["offset"];
  if (src["skip"]) filter.skip = src["skip"];
  if (src["order"]) filter.order = src["order"].map((item) => item.join(" "));
  if (where) filter.where = parseWhere<TSrc>(src, where);

  return filter;
}
