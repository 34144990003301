import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { App } from "./app";
import "./index.css";
import { serviceWorkerConfig } from "./modules/offline/service-worker-config";
import * as serviceWorkerRegistrator from "./modules/offline/service-worker-registrator";
import { reportWebVitals } from "./reportWebVitals";
import { setupStore } from "./store";

const container = document.getElementById("root") as HTMLDivElement;
const root = createRoot(container);
root.render(
  <Provider store={setupStore()}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistrator.register(serviceWorkerConfig);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
