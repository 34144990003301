import { IObservationDto } from "../dtos/observations.dto";
import { TObservationsUpdateDto } from "../dtos/observations.update.dto";

export function observationDtoToObservationUpdateDto(dto: IObservationDto): TObservationsUpdateDto {
  const result = {
    ...dto,
  };

  // TODO: IDE doesn't understand overpassed properties
  delete result["crop"];
  delete result["appUser"];
  delete result["farm"];
  delete result["farmLand"];
  delete result["phenoPhase"];
  delete result["observationPhotos"];

  return result;
}
