import { FeatureCollection, Geometry } from "geojson";

import { TArcgisMapCropPropsDto } from "../dtos/arcgis-map-crop-props.dto";
import { BaseRestService } from "../../../../shared/services/base-rest.service";

export type CropMap = FeatureCollection<Geometry, TArcgisMapCropPropsDto>;

class GeoJsonService extends BaseRestService {
  public readonly ROOT_URL: string =
    process.env.REACT_APP_GEOJSON_REST_SERVICE_URL ||
    "ADD_INTO_.env:REACT_APP_GEOJSON_REST_SERVICE_URL";

  getMapData({
    farmId,
    seasonId,
  }: {
    farmId: string;
    seasonId: string;
  }): Promise<CropMap> {
    // https://maps.ekoniva-apk.org/arcgis/rest/services/test/ekocrop/FeatureServer/0/query?where=...
    // protected path = `ekocrop/FeatureServer/0`;

    // https://ekocrop-test.ekoniva-apk.org/api/FarmLands/getFarmLandGeometriesBySeasonAndFarm
    const ret = super.fetch3<CropMap>({
      endpointPath: `FarmLands/getFarmLandGeometriesBySeasonAndFarm`,
      searchParams: {
        farmId: farmId,
        seasonId: seasonId,
        withGeometryForSwaggerFalse: true,
      },

      // swaggerAuthToken: "T9pQmN2t3mgYNNnRwZEGabQ34f5n7Fh77gJDFnD7AOts5Mf2Wu6gQ6K3ouklxymO",

      // provideMock: () => this.mockEmptyJsonString,
      // provideMock: this.provideMockMezhdurechie,
    });
    return ret;
  }

  // смоленская нива 2023 возвращает то что парсилось с ошибкой:
  // FAILED /api/FarmLands/getFarmLandGeometriesBySeasonAndFarm:
  // {
  //   name: 'TypeError',
  //   message: "Cannot read properties of undefined (reading 'lat')",
  //   stack: 'TypeError: Cannot read properties of undefined (re…iva-apk.dev/static/js/main.9eda037e.js:2:1981161)';
  // }
  private mockEmptyJsonString = JSON.parse('{"type":"FeatureCollection","features":[]}');

  // during PROD build, 3Mb JSON will be stripped out from resulting bundle.js
  // private async provideMockMezhdurechie(): Promise<CropMap> {
  //   const mockDelayMillis = 1500;
  //   await new Promise((f) => setTimeout(f, mockDelayMillis));
  //   // https://stackoverflow.com/questions/31758081/loading-json-data-from-local-file-into-react-js
  //   const ret = require("./geojson.mock.mezhdurechie-2023.json");
  //   return ret;
  // }
}

export const geoJsonService = new GeoJsonService();
