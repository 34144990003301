import { Role } from "../../../shared/enums/role";
import { IUserDto } from "../dtos/user.dto";
import { IUser } from "../interfaces/user";

export class User implements IUser {
  readonly id: string;
  lastName: string;
  firstName: string;
  middleName: string | null;
  fullName: string;
  positionName: string;
  defaultFarmId: string;
  phone: string | null;
  avatarUrl: string | null;
  roles: Record<Role, number>;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IUserDto {
    return {
      id: this.id,
      lastName: this.lastName,
      firstName: this.firstName,
      middleName: this.middleName,
      fullName: this.fullName,
      positionName: this.positionName,
      defaultFarmId: this.defaultFarmId,
      phone: this.phone,
      avatarUrl: this.avatarUrl || undefined,
      roles: Object.entries(this.roles).map(([key, value]) => ({ id: value, name: key as Role })),
    };
  }

  updateFromDto(dto: IUserDto): void {
    this.lastName = dto.lastName;
    this.firstName = dto.firstName;
    this.middleName = dto.middleName;
    this.fullName = dto.fullName;
    this.positionName = dto.positionName;
    this.defaultFarmId = dto.defaultFarmId;
    this.phone = dto.phone || "";
    this.avatarUrl = dto.avatarUrl || null;
    this.roles = dto.roles.reduce(
      (acc, item) => ({
        ...acc,
        [item.name]: item.id,
      }),
      {} as Record<Role, number>
    );
  }
}
