import { devlog } from "../shared/utils/log";
import { API_URL } from "../config/api";
// TODO: need to rewrite this file

/**
 * FIND all instances of the model / a model instance by {{id}} matched by filter from the data source.
 * @param {string} endpoints endpoints request api
 * @param {string} id Optional. Model id
 * @param {string} part Optional.
 * @param {number} limit Optional.
 * @param {string[]} fields Optional. ["id", "name", "seasonId"]
 * @param {string[]} includes Optional. ["crop", "appUser", "farm"]
 * @param {any} where Optional. {
        and: [
          { inputDate: { gt: ... } },
          { inputDate: { lt: ... } },
          { seasonId: ... },
        ],
      }
 */

export async function getFeaturesAPI<T>(
  endpoints: string,
  id?: string,
  part?: string,
  limit?: number,
  fields?: string[],
  includes?: { [key: string]: string[] }[],
  //eslint-disable-next-line
  where?: any
): Promise<T | undefined> {
  const ACCESS_TOKEN = localStorage["access_token"];
  if (!ACCESS_TOKEN) return;

  let url = `${API_URL}/${endpoints}`;
  if (id) url = url + `/${id}`;
  if (part) url = url + `/${part}`;
  url = url + `?access_token=${ACCESS_TOKEN}`;

  const filter = {};

  if (limit) filter["limit"] = limit;
  if (includes)
    filter["include"] = includes.map((item) => {
      const keyName = Object.keys(item)[0];
      return {
        relation: keyName,
        scope: { fields: item[keyName] },
      };
    });
  if (fields) filter["fields"] = fields;
  if (where) filter["where"] = where;

  url = url + `&filter=${JSON.stringify(filter)}`;

  const responseURL = new URL(url);

  try {
    const response = await fetch(responseURL.toString(), {});
    if (!response.ok) {
      devlog(`HAPPENS_ON_Roles:_401_Unauthorized`); // , response
      return undefined; // to setInfoData({roles: roles})
    }
    const jsonResponse: T = await response.json();
    return jsonResponse;
  } catch {
    // eslint-disable-next-line no-console
    console.log(`Ошибка загрузки данных.`);
    return;
  }
}
