import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { deverror } from "../../../shared/utils/log";
import { IFarmLandDto } from "../shared/dtos/farm-land.dto";
import { IFarmLandsState } from "../shared/interfaces/farm-lands-state";
import { FarmLand } from "../shared/models/farm-land";
import { farmLandsService } from "../shared/services/farm-lands.service";

const initialState: IFarmLandsState = {
  item: null,
  isLoading: false,
  list: [],
  listCount: 0,
  currentFarmLands: undefined,
  currentFarmLandsCacheKey: undefined,
};
export const FIELDS_MODULE_NAME = "farmLands";
export const farmLandsSlice = createSlice({
  name: FIELDS_MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<FarmLand[]>): void {
      state.list = action.payload;
    },
    setListCountAction(state, action: PayloadAction<number>): void {
      state.listCount = action.payload;
    },
    setDetailsAction(state, action: PayloadAction<FarmLand | null>): void {
      state.item = action.payload;
    },
    setCurrentFarmLandsAction(state, action: PayloadAction<IFarmLandDto[]>): void {
      state.currentFarmLands = action.payload;
    },
    setCurrentFarmLandsCacheKeyAction(
      state,
      action: PayloadAction<string | undefined>
    ): void {
      state.currentFarmLandsCacheKey = action.payload;
    },
    clearDetailsAction(state): void {
      state.item = null;
    },
  },
  extraReducers(builder) {
    builder
      // can be deleted: farm-lands.rtkq.ts has a fresh implementation
      // see farm-land-master.tsx for details
      .addCase(fetchFarmLandsAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchFarmLandsAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchFarmLandsAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const farmLandsReducer = farmLandsSlice.reducer;
export const {
  setListAction,
  setDetailsAction,
  clearDetailsAction,
  setListCountAction,
  setCurrentFarmLandsAction, // IFarmLandDto
  setCurrentFarmLandsCacheKeyAction,
} = farmLandsSlice.actions;
// async actions

// can be deleted: farm-lands.rtkq.ts has a fresh implementation
// see farm-land-master.tsx for details
export const fetchFarmLandsAction = createAsyncThunk<void, ILoopbackFilter>(
  `${FIELDS_MODULE_NAME}/fetchFarmLands`,
  async (filter, { dispatch }) => {
    const count = await farmLandsService.listCount(filter);
    const dtos = await farmLandsService.list(filter);
    const models = dtos.map((dto) => {
      const model = new FarmLand();
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
    // TODO: depends of optimisation needs, we could change it only if filter changes
    dispatch(setListCountAction(count.count));
  }
);

// can be deleted: farm-lands.rtkq.ts has a fresh implementation
export const fetchFarmLandByIdAction = createAsyncThunk<void, string>(
  `${FIELDS_MODULE_NAME}/fetchFarmLandById`,
  async (id, { dispatch }) => {
    if (id === null) {
      return;
    }

    const dto = await farmLandsService.get(id);
    const model = new FarmLand();
    model.updateFromDto(dto);
    dispatch(setDetailsAction(model));
  }
);

// can be deleted: farm-lands.rtkq.ts has a fresh implementation
export const fetchFarmLandIdByArcGisIdAction = createAsyncThunk<string | null, string>(
  `${FIELDS_MODULE_NAME}/fetchFarmLandByArcGisId`,
  async (id) => {
    if (id === null) {
      return null;
    }

    const dto = await farmLandsService.getByArcGisId(id);
    return dto ? dto.id : null;
  }
);

export type FetchFarmLandsRequest = {
  farmId: string;
  seasonId: string;
};

export const fetchFarmLandsByFarmId = createAsyncThunk<
  IFarmLandDto[],
  FetchFarmLandsRequest
>(
  `${FIELDS_MODULE_NAME}/fetchFarmLandsByFarmId`,
  async ({ farmId, seasonId }, { dispatch, rejectWithValue }) => {
    const cachedKey = `${farmId}:${seasonId}`;
    dispatch(setCurrentFarmLandsCacheKeyAction(cachedKey));

    try {
      const request = {
        farmId: farmId,
        excludeGeometry: true,
        filter: {
          where: {
            seasonId: seasonId,
            isDeleted: false,
            // copypaste from currentFarmLandsApiSlice *_RtkQ
            // isDeleted: { neq: null },
          },
        },
      };
      const dtos: IFarmLandDto[] = await farmLandsService.listByFarmId(request);
      dtos.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
      dispatch(setCurrentFarmLandsAction(dtos));
      return dtos;
    } catch (err) {
      dispatch(setCurrentFarmLandsCacheKeyAction(undefined));
      deverror(`FAILED_fetchFarmLandsByFarmId() ; cachedKey[${cachedKey}] => undefined`);
      return rejectWithValue(err);
    }
  }
);
