import { TechAssetType } from "../../../../shared/enums/tech-asset-type";
import { ITechAssetsDto, ITechAssetsWithIncludeDto } from "../dtos/tech-assets";

export class TechAsset {
  readonly id: string;
  name: string;
  techTypeName: string;
  importTechBrand: string;
  techModel: string;
  stateNumber: string;
  inventoryNumber: string;
  techFactoryNum: string;
  techEngineNum: string;
  importFarmName: string;
  techOwner: string;
  category: TechAssetType;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): ITechAssetsDto {
    return {
      id: this.id,
      name: this.name,
      techTypeName: this.techTypeName,
      importTechBrand: this.importTechBrand,
      techModel: this.techModel,
      stateNumber: this.stateNumber,
      inventoryNumber: this.inventoryNumber,
      techFactoryNum: this.techFactoryNum,
      techEngineNum: this.techEngineNum,
      importFarmName: this.importFarmName,
      techOwner: this.techOwner,
      category: this.category,
    };
  }

  updateFromDto(dto: ITechAssetsDto): void {
    this.name = dto.name;
    this.techTypeName = dto.techTypeName;
    this.importTechBrand = dto.importTechBrand;
    this.techModel = dto.techModel;
    this.stateNumber = dto.stateNumber;
    this.inventoryNumber = dto.inventoryNumber;
    this.techFactoryNum = dto.techFactoryNum;
    this.techEngineNum = dto.techEngineNum;
    this.importFarmName = dto.importFarmName;
    this.techOwner = dto.techOwner;
    this.category = dto.category;
  }
}

export class TechAssetWithInclude extends TechAsset {
  farms: Set<string>; // non-nullable as listWithIncludes requests it from backend => must provide
  techOperationSubGroups: Set<string>; // non-nullable as listWithIncludes requests it from backend => must provide

  updateFromDto(dto: ITechAssetsWithIncludeDto): void {
    super.updateFromDto(dto);

    this.farms = new Set(dto.farms.map(x => x.id)); // fighting with duplicates
    this.techOperationSubGroups = new Set(dto.techOperationSubGroups.map(x => x.id));
  }
}
