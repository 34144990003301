import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";

import { FormControl, MenuItem, Select, Skeleton } from "@mui/material";

import { getFarmNameTuples } from "../../../../../pages/map-page/store/map-page.selector";
import { useAppDispatch } from "../../../../../store";
import { getFilter, getFilterDisabling, getInit } from "../../../store/filters.selector";
import { setFilterAction } from "../../../store/filters.slice";
import { FieldLabel } from "../../components/field-label";
import { FilterName } from "../../enums/filter-name";

export const useArcgisNameEffect = (): void => {
  const farmTuples = useSelector(getFarmNameTuples);
  const dispatch = useAppDispatch();
  const arcgisName = useSelector(getFilter(FilterName.ArcgisFarmName));
  const farmId = useSelector(getFilter(FilterName.FarmId));
  const isFiltersInit = useSelector(getInit);

  useEffect(() => {
    if (!farmTuples.length || !isFiltersInit) {
      return;
    }

    if (arcgisName === null) {
      dispatch(
        setFilterAction({
          filter: FilterName.ArcgisFarmName,
          value: farmTuples[0].nameArcgis,
        })
      );
      dispatch(setFilterAction({ filter: FilterName.FarmId, value: farmTuples[0].id }));
    } else {
      const tuple = farmTuples.find((item) => item.nameArcgis === arcgisName);

      if (tuple) {
        dispatch(setFilterAction({ filter: FilterName.FarmId, value: tuple.id }));
        dispatch(
          setFilterAction({
            filter: FilterName.FarmName,
            value: tuple.nameEkocrop,
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [farmTuples, arcgisName, isFiltersInit]);

  // try to update arcgisName if ekocropFarmId have been changed
  useEffect(() => {
    if (!farmTuples.length || !isFiltersInit || !farmId) {
      return;
    }
    const tuple = farmTuples.find((item) => item.id === farmId.toString());

    if (!tuple) {
      return;
    }

    dispatch(
      setFilterAction({
        filter: FilterName.ArcgisFarmName,
        value: tuple.nameArcgis,
      })
    );
    // eslint-disable-next-line
  }, [farmTuples, farmId, isFiltersInit]);
};

export const FilterFieldArcgisName = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const farmTuples = useSelector(getFarmNameTuples);
  const value = useSelector(getFilter(FilterName.ArcgisFarmName));
  const isArcgisFarmNameDisabled = useSelector(
    getFilterDisabling(FilterName.ArcgisFarmName)
  );

  const onChangeHandler = useCallback(
    (event) => {
      dispatch(
        setFilterAction({
          filter: FilterName.ArcgisFarmName,
          value: event.target.value,
        })
      );
    },
    // eslint-disable-next-line
    [dispatch, setFilterAction]
  );

  useArcgisNameEffect();

  if (!farmTuples.length) {
    return <Skeleton width={180} height={60} />;
  }

  return (
    <FormControl variant="standard" margin="normal">
      <FieldLabel id="arcgis-name-label">Хозяйство</FieldLabel>
      <Select
        variant="standard"
        labelId="arcgis-name-label"
        id="arcgis-name-select"
        value={value || ""}
        onChange={onChangeHandler}
        disabled={isArcgisFarmNameDisabled}
      >
        {farmTuples.map((item) => (
          <MenuItem key={item.id} value={item.nameArcgis}>
            {item.nameEkocrop}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
