import { BreakpointsOptions } from "@mui/system";

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 560,
    tablet: 640,
    md: 960,
    lg: 1280,
    desktop: 1920,
    xl: 1920,
  },
};
