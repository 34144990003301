import { createTheme } from "@mui/material/styles";

import { components } from "./components";
import { palette } from "./palette";
import { typography } from "./typography";
import { breakpoints } from "./breakpoints";

export const MuiTheme = createTheme({
  breakpoints,
  palette,
  typography,
  components,
});
