import { JSONSchemaType } from "ajv";
import { DefaultSchemaValue } from "../../../verification/shared/enums/default-schema-value";
import { IMateriallyAccountableEmployeeDto } from "./materially-accountable-employee.dto";
import { TimestampSchema } from "../../../../shared/dtos/timestamps.v2.schema";

export const MateriallyAccountableEmployeeSchema: JSONSchemaType<IMateriallyAccountableEmployeeDto> =
  {
    type: "object",
    properties: {
      id: { type: "string", default: DefaultSchemaValue.STRING },
      personalId: { type: "string", default: DefaultSchemaValue.STRING },
      farmId: { type: "string", default: DefaultSchemaValue.STRING },
      erpId: { type: "string", default: DefaultSchemaValue.STRING },
      name: { type: "string", default: DefaultSchemaValue.STRING },
      idDepGroup: { type: "string", default: DefaultSchemaValue.STRING },
      ...TimestampSchema.properties,
    },
    required: [
      "id",
      "personalId",
      "farmId",
      "erpId",
      "name",
      ...TimestampSchema.required,
    ],
  };

export const MateriallyAccountableEmployeeListSchema: JSONSchemaType<
  IMateriallyAccountableEmployeeDto[]
> = {
  type: "array",
  items: MateriallyAccountableEmployeeSchema,
};
