import { Palette } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

export const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
  button: {
    fontSize: "16px",
    textTransform: "inherit",
    fontFamily: "Roboto-Regular, sans-serif",
  },
};
