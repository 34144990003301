import { CropMap } from "../../pages/map-page/shared/services/arcgis-crops.service";

export type ArcGisError = {
  code: number;
  details: string[];
  message: string;
};

// https://www.typescriptlang.org/docs/handbook/advanced-types.html
export function isArcGisError(list: CropMap | ArcGisError): list is ArcGisError {
  return (list as ArcGisError).code !== undefined;
}
