import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IWeedsState } from "../shared";
import { RootState } from "../../../store-types";
import { WEEDS_MODULE_NAME } from "./constants";

export const getWeedsState = (rootState: RootState): IWeedsState =>
  rootState[WEEDS_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getWeedsState,
  (state: IWeedsState) => state.isLoading
);
export const getList = createDraftSafeSelector(
  getWeedsState,
  (state: IWeedsState) => state.list
);
export const getSelectedIds = createDraftSafeSelector(
  getWeedsState,
  (state: IWeedsState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getWeedsState,
  (state: IWeedsState) => state.searchText
);
