import { InputLabel } from "@mui/material";

export const FieldLabel = ({ children, id }) => (
  <InputLabel
    id={id}
    sx={{
      position: "relative",
      paddingRight: 25,
      marginTop: "10px",
      marginBottom: "-26px",
    }}
  >
    {children}
  </InputLabel>
);
