import { SET_INFO_DATA } from "./info-data-actions";
import { InfoDataState } from "./info-data-types";

const INFO_DATA_INITIAL_STATE: InfoDataState = {
  // replaced with dispatch(fetchCropTypesAction()).unwrap(),
  // cropTypes: [],

  departments: [],
  employees: [],
  farms: [],
  positions: [],
  seasons: undefined, // undefined => NOT_YET_LOADED
  workPlace: [],
  techTypes: [],
  techOperationGroups: [],
  techOperationSubGroups: [],
  techAssets: [],
  techAssetCategory: [],
  phenoPhases: [],
  techOperationsScalars: [],
  initialDictionariesLoaded: false,
};

// eslint-disable-next-line
export const infoDataReducer = (state = INFO_DATA_INITIAL_STATE, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INFO_DATA:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
