import { JSONSchemaType } from "ajv";
import { ITimestampsDto } from "./timestamps.v2.dto";
import { schemaNullableField } from "../../modules/verification/shared/utils/schema-nullable-field";
import { DefaultSchemaValue } from "../../modules/verification/shared/enums/default-schema-value";

export const TimestampSchema: JSONSchemaType<ITimestampsDto> = {
  type: "object",
  properties: {
    createdAt: schemaNullableField({ type: "string", format: "iso-date-time" }),
    updatedAt: schemaNullableField({ type: "string", format: "iso-date-time" }),
    deletedAt: schemaNullableField({ type: "string", format: "iso-date-time" }),
    createdByUserId: schemaNullableField({ type: "string" }),
    updatedByUserId: schemaNullableField({ type: "string" }),
    isDeleted: { type: "boolean", default: DefaultSchemaValue.BOOLEAN },
  },
  required: [
    "createdAt",
    "updatedAt",
    "deletedAt",
    "createdByUserId",
    "updatedByUserId",
    "isDeleted",
  ],
};
