import { v4 as uuidv4 } from "uuid";

import {
  ITechOperationAssetDto,
  TechOperationAssetDtoWithoutUnitsType,
} from "../dtos/tech-operation-asset.dto";
import { TechOperationAssetEntityType } from "../types/tech-operation-asset-entity";
import { ITechOperationAssetFormData } from "../interfaces/tech-operation-asset-form-data";

export class TechOperationAsset {
  readonly id: string;
  farmId: string;
  techOperationId: string;
  name: string;
  entityType: TechOperationAssetEntityType;
  entityId: string;
  quantity: string | number;
  quantityReal: number;
  applicationRate: number;
  unit: string;
  units: string[];
  seedUnitWeight: number | null;
  isDeleted?: boolean;
  createdAt?: number; // timestamp

  constructor(id: string = uuidv4()) {
    this.id = id.toUpperCase();
  }

  static fromDto(assetDto: TechOperationAssetDtoWithoutUnitsType): TechOperationAsset {
    const result = new TechOperationAsset(assetDto.id);
    result.updateFromDto(assetDto);
    return result;
  }

  get asDto(): ITechOperationAssetDto {
    return {
      id: this.id,
      farmId: this.farmId,
      techOperationId: this.techOperationId,
      entityType: this.entityId, // swapped back for backend's convenience
      entityId: this.entityType, // swapped back for backend's convenience
      quantity: Number(this.quantity),
      quantityReal: Number(this.quantityReal),
      applicationRate: Number(this.applicationRate),
      unit: this.unit,
      units: this.units,
      seedUnitWeight: this.seedUnitWeight,
      name: this.name,
    };
  }

  updateFromDto(dto: TechOperationAssetDtoWithoutUnitsType): TechOperationAsset {
    this.farmId = dto.farmId;
    this.techOperationId = dto.techOperationId;
    this.entityType = dto.entityId; // dto.entityId must be GUID
    this.entityId = dto.entityType; // dto.entityType must be  TechOperationAssetEntityType
    this.quantityReal = dto.quantityReal;
    this.applicationRate = dto.applicationRate;
    this.unit = dto.unit;
    this.seedUnitWeight = dto.seedUnitWeight;
    this.name = dto.name;
    this.createdAt = dto.createdAt;
    return this;
  }

  get asFormData(): ITechOperationAssetFormData {
    return {
      id: this.id || "",
      entityType: this.entityType || "",
      entityId: this.entityId || "",
      quantity: this.quantity || "",
      quantityReal: this.quantityReal || 0,
      applicationRate: this.applicationRate || 0,
      unit: this.unit || "",
      units: this.units || "",
      seedUnitWeight: this.seedUnitWeight || 0,
      createdAt: this.createdAt || 0,
      name: this.name || "",
    };
  }

  updateFromFormData(data: ITechOperationAssetFormData): TechOperationAsset {
    this.entityId = data.entityId;
    this.entityType = data.entityType;
    this.quantityReal = data.quantityReal;
    this.applicationRate = data.applicationRate;
    this.unit = data.unit;
    this.units = data.units;
    this.seedUnitWeight = data.seedUnitWeight;
    this.name = data.name;
    return this;
  }

  setEntityType(entityType: TechOperationAssetEntityType): TechOperationAsset {
    this.entityType = entityType;
    return this;
  }

  setFarmId(farmId: string): TechOperationAsset {
    this.farmId = farmId;
    return this;
  }

  setTechOperationId(techOperationId: string): TechOperationAsset {
    this.techOperationId = techOperationId;
    return this;
  }
}
