import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Box, CircularProgress, Grid2 } from "@mui/material";

import { getFarmNameTuples } from "../../../../../pages/map-page/store/map-page.selector";
import {
  fetchAvailableFarmsAction,
  setSelectedFarmNameAction,
} from "../../../../../pages/map-page/store/map-page.slice";
import { useAppDispatch } from "../../../../../store";
import { getFilter } from "../../../store/filters.selector";
import { FilterName } from "../../enums/filter-name";
import {
  FilterFieldArcgisName,
  useArcgisNameEffect,
} from "../filter-field-arcgis-name/filter-field-arcgis-name";
import { FilterFieldSeasonId } from "../filter-field-season-id/filter-field-season-id";
import { useSeasonEffect } from "../../effects/season";

export const FilterFarmAndSeason = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const ArcgisFarmName = useSelector(getFilter(FilterName.ArcgisFarmName));
  const farmTuples = useSelector(getFarmNameTuples);

  // TODO: each request should track it's own:
  // { isLoading, response, request, error, etc... }
  // otherwise we will spend 100 lines of code to keep isLoadingList,
  // isLoadingEntity, isLoadingParams, isLoadingLinkedEntities in REDUX store;
  // REDUX is too verbose; consider:
  // 1) useFetch() https://habr.com/ru/articles/752790/
  // 2) useQuery() https://reactdev.ru/libs/react-query/#_2
  const [isFarmsLoading, setFarmsLoading] = useState(false);

  useEffect(() => {
    if (!ArcgisFarmName) {
      return;
    }
    dispatch(setSelectedFarmNameAction(ArcgisFarmName));
  }, [dispatch, ArcgisFarmName]);

  // set defaults for season and farm
  useSeasonEffect();
  useArcgisNameEffect();

  // (initialize) get tuples eko/arcgis names
  useEffect(() => {
    if (farmTuples.length > 0) {
      return; // duplicate request removed (on field clicked)
    }
    // only first render should request the backend
    setFarmsLoading(true);
    dispatch(fetchAvailableFarmsAction())
      .unwrap()
      .finally(() => setFarmsLoading(false));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid2 container={true} spacing={1}>
        {isFarmsLoading && (
          <Grid2>
            <Box
              px={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100%"}
            >
              <CircularProgress size={18} style={{ margin: "25px" }} />
            </Box>
          </Grid2>
        )}

        <Grid2>
          <FilterFieldArcgisName />
        </Grid2>

        <Grid2>
          <FilterFieldSeasonId />
        </Grid2>
      </Grid2>
    </>
  );
};
