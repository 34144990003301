import { IFarmLandDto } from "../../pages/fields/shared/dtos/farm-land.dto";

export interface ICurrentFarmLandsState {
  isLoading: boolean;
  сurrentFarmLands_includeDeleted?: IFarmLandDto[]; // undefined when NOT_YET_LOADED
  сurrentFarmLands_nonDeleted?: IFarmLandDto[]; // undefined when NOT_YET_LOADED
}

export const initialState: ICurrentFarmLandsState = {
  isLoading: false,
  сurrentFarmLands_includeDeleted: undefined,
  сurrentFarmLands_nonDeleted: undefined,
};
