import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IPaginationState } from "../../../modules/filter/shared/interfaces/pagination-types";
import { RootState } from "../../../store-types";
import {
  ITechOpListFilter,
  ITechOperationsState,
} from "../shared/interfaces/tech-operations-state";
import { TECHOPERATIONS_MODULE_NAME } from "./constants";

export const getTechOperationsState = (rootState: RootState): ITechOperationsState =>
  rootState[TECHOPERATIONS_MODULE_NAME];

export const getNoPredecessorsList = createDraftSafeSelector(
  getTechOperationsState,
  (state: ITechOperationsState) => state.noPredecessorsList
);

export const getRemoveSplittedFields: (_) => boolean = createDraftSafeSelector(
  getTechOperationsState,
  (state: ITechOperationsState) => state.removeSplittedFields
);

export const getFilter: (_) => ITechOpListFilter = createDraftSafeSelector(
  getTechOperationsState,
  (state: ITechOperationsState) => state.filter
);

export const getPagination: (_) => IPaginationState = createDraftSafeSelector(
  getTechOperationsState,
  (state: ITechOperationsState) => state.pagination
);
