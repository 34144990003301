import { HeadCell } from "../../../../components/enhanced-table-head/enhanced-table-head";
import { AssetUsageRecord } from "../models/asset-usage-record";
import { ColumnName } from "../enums/column-name";

export const assetUsageRecordColumnLabels: HeadCell<keyof AssetUsageRecord>[] = [
  { id: ColumnName.FarmLandName, label: "Номер поля" },
  { id: ColumnName.DateCreated, label: "Дата работы" },
  { id: ColumnName.TechOperationSubGroupName, label: "Подгруппа техопераций" },
  {
    id: ColumnName.PersonCreatedName,
    label: "Пользователь, который завел техоперацию",
  },
  { id: ColumnName.PersonResponsibleName, label: "МОЛ" },
  { id: ColumnName.AssetType, label: "Вид ТМЦ" },
  { id: ColumnName.AssetName, label: "ТМЦ" },
  { id: ColumnName.Quantity, label: "Количество ТМЦ" },
  { id: ColumnName.FieldSize, label: "Площадь" },
  { id: ColumnName.Norma, label: "Норма" },
  { id: ColumnName.CropTypeName, label: "Культура" },
  { id: ColumnName.Status, label: "Статус" },
  { id: ColumnName.Comment, label: "Комментарий" },
];
