import Ajv, { AnySchema, Options } from "ajv";
import addFormats from "ajv-formats";
import ajvErrors from "ajv-errors";
import {
  IVerificationService,
  TOnErrorHandler,
} from "../interfaces/verification-service";

type AnyValidateFunction = ReturnType<typeof Ajv.prototype.compile>;

class VerificationService implements IVerificationService {
  private static instance: VerificationService;
  private enabled = false;
  private validators = new Map<string, AnyValidateFunction>();
  private onErrorHandler: TOnErrorHandler = (errors, message) => {
    return console.error(message || "Invalid backend response:", errors);
  };

  constructor() {
    if (VerificationService.instance) {
      return VerificationService.instance;
    }
    VerificationService.instance = this;
  }

  check<TSchema extends AnySchema>(
    options: {
      schema: TSchema;
      message?: string;
      onError?: TOnErrorHandler;
      cacheId?: string;
    } & Options
  ): <T>(data: T) => T {
    if (!this.enabled) {
      return (data) => data;
    }

    const { schema, cacheId, message, onError = this.onErrorHandler, ...opts } = options;
    const validate = this.buildValidate(schema, opts, cacheId);

    return (data) => {
      if (!validate(data)) {
        onError(validate.errors, message);
      }

      return data;
    };
  }

  onError(handler: TOnErrorHandler): void {
    this.onErrorHandler = handler;
  }

  enable(value: boolean): void {
    this.enabled = value;
  }

  private buildValidate(
    schema: AnySchema,
    options: Options,
    cacheId?: string
  ): AnyValidateFunction {
    const cachedValidator = this.validators.get(cacheId || "");
    if (cachedValidator) {
      return cachedValidator;
    }

    let ajv = new Ajv({
      removeAdditional: "all",
      coerceTypes: true,
      useDefaults: "empty",
      allErrors: true, // Report all errors, not just the first one
      verbose: true, // Output additional validation messages
      ...options,
    });
    ajv = addFormats(ajv);
    ajv = ajvErrors(ajv);

    let validate: AnyValidateFunction;
    try {
      validate = ajv.compile(schema);
    } catch (e) {
      console.error(`Validation schema is not correct ${JSON.stringify(schema)}`);
      throw e;
    }

    if (cacheId) {
      this.validators.set(cacheId, validate);
    }

    return validate;
  }
}

export const verificationService = new VerificationService();
