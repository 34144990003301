import { round } from "lodash";
import { AssetType } from "../enums/asset-type";
import { AssetUsageRecord } from "./asset-usage-record";
import { TLabel } from "../../../../shared/interface";

export class AssetUsageComposition {
  static getItemId(item: AssetUsageRecord): string {
    return item.personResponsibleId + item.assetType + item.assetName + item.cropTypeId;
  }
  private _items: AssetUsageRecord[] = [];

  get id(): string {
    return AssetUsageComposition.getItemId(this.item);
  }

  get item(): AssetUsageRecord {
    if (!this._items.length) {
      throw new Error("A try to get data from empty assetUsage composition");
    }
    return this._items[0];
  }

  get items(): AssetUsageRecord[] {
    return this._items;
  }

  get personResponsibleId(): string {
    return this.item.personResponsibleId;
  }
  get personResponsibleName(): string {
    return this.item.personResponsibleName;
  }
  get assetType(): AssetType {
    return this.item.assetType;
  }

  get assetName(): string {
    return this.item.assetName;
  }

  get quantity(): number {
    const sum = this._items.reduce((acc, item) => acc + item.quantity, 0);
    return round(sum, 3);
  }

  get quantityTitle(): string {
    const item = this._items[0];
    return `${this.quantity} ${item.unit}`;
  }

  get fieldSize(): number {
    const sum = this._items.reduce((acc, item) => acc + item.fieldSize, 0);
    return round(sum, 2);
  }

  get norma(): number {
    const sum = this._items.reduce((acc, item) => acc + item.norma, 0);
    const len = this._items.length;
    return round(sum / len, 5);
  }

  get cropTypes(): TLabel[] {
    const uniqueIds = new Set();
    return this._items
      .filter((item) => {
        if (uniqueIds.has(item.cropTypeId)) {
          return false;
        }

        uniqueIds.add(item.cropTypeId);
        return true;
      })
      .map((item) => ({
        id: item.cropTypeId,
        title: item.cropTypeName,
      }));
  }

  get cropTypeNames(): string {
    return this.cropTypes.map((item) => item.title).join(", ");
  }

  constructor(items: AssetUsageRecord[] = []) {
    this._items.push(...items);
  }
}
