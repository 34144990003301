import React, { useCallback } from "react";

import { Close as CloseIcon, Search as SearchIcon } from "@mui/icons-material";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";

import { CATALOG_SECTIONS_INFO } from "./catalog-tab-layout-constants";
import { CatalogTabLayoutProps } from "./catalog-tab-layout-types";
import { catalogTabpanelStyles } from "./catalog-tab-layout.style";

export const CatalogTabLayout = (props: CatalogTabLayoutProps): JSX.Element | null => {
  const { children, type, onSearch } = props;
  const classes = catalogTabpanelStyles();

  const [searchText, setSearchText] = React.useState<string>("");

  const catalogTabInfo = CATALOG_SECTIONS_INFO[type];

  const onSubmit = useCallback((e) => {
    e.preventDefault();
  }, []);

  const onSearchChange = useCallback(
    (text: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(text.target.value);
      if (onSearch) {
        onSearch(text.target.value);
      }
    },
    [onSearch]
  );

  const onSearchClear = useCallback(() => {
    setSearchText("");
    if (onSearch) {
      onSearch("");
    }
  }, [setSearchText, onSearch]);

  return (
    <Box sx={classes.root}>
      <Box sx={classes.manualHeader}>{catalogTabInfo.name}</Box>
      <form style={classes.inputSearch} noValidate autoComplete="off" onSubmit={onSubmit}>
        {onSearch && (
          <TextField
            id="catalog-tab-layout-search-field"
            variant="outlined"
            inputProps={{ "aria-label": "Полнотекстовый поиск внутри каталога" }}
            label="Найти"
            onChange={onSearchChange}
            size="small"
            value={searchText}
            style={{
              minWidth: "420px",
              marginBottom: "12px",
            }}
            InputProps={{
              endAdornment: [
                searchText.length > 0 && (
                  <CloseIcon
                    key="closeIcon"
                    onClick={onSearchClear}
                    style={{ cursor: "pointer" }}
                  />
                ),
                <SearchIcon key="searchIcon" />,
              ],
            }}
          />
        )}
      </form>

      <Box>{children}</Box>
    </Box>
  );
};
