import { IChemicalsDto } from "../dtos/chemicals";
import { Units } from "../enums/units";

export class Chemical {
  readonly id: string;
  name: string;
  type: string;
  unit: (typeof Units)[keyof typeof Units];
  units?: string[];

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): IChemicalsDto {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      unit: this.unit,
      units: this.units,
    };
  }

  updateFromDto(dto: IChemicalsDto): void {
    this.name = dto.name;
    this.type = dto.type;
    this.unit = dto.unit;
    this.units = dto.units;
  }
}
