import { ISeedsDto } from "../dtos/seeds";
import { Units } from "../enums/units";

export class Seed {
  readonly id: string;
  name: string;
  unit?: (typeof Units)[keyof typeof Units];
  units?: string[];
  isDeleted: boolean;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): ISeedsDto {
    return {
      id: this.id,
      name: this.name,
      sortName: null,
      varietyName: null,
      units: this.units,
      unit: this.unit,
      isDeleted: this.isDeleted,
    };
  }

  updateFromDto(dto: ISeedsDto): void {
    this.name = dto.name;
    this.units = dto.units;
    this.unit = dto.unit;
    this.isDeleted = dto.isDeleted || false;
  }

  clone(): Seed {
    return Object.assign(new Seed(this.id), this);
  }
}
