export enum ColumnName {
  FarmLandName = "farmLandName",
  DateCreated = "dateCreated",
  TechOperationSubGroupName = "techOperationSubGroupName",
  PersonCreatedName = "personCreatedName",
  PersonResponsibleName = "personResponsibleName",
  AssetType = "assetType",
  AssetName = "assetName",
  Quantity = "quantity",
  Norma = "norma",
  FieldSize = "fieldSize",
  CropTypeName = "cropTypeName",
  Status = "status",
  Comment = "comment",
}
