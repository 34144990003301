import { ILoopbackFilter } from "../../../../modules/filter/shared/interfaces/loopback";
import { BaseService } from "../../../../shared/services";
import { ITechOperationDto } from "../dtos/tech-operation.dto";
import { ICropReportFilter } from "../interfaces/crop-report-filter";

class TechOperationsService extends BaseService<ITechOperationDto, ILoopbackFilter> {
  private path = "TechOperationAgregatedsV2";

  async get(id: string): Promise<ITechOperationDto> {
    const url = [this.path, id].join("/");
    const dto = await super.fetch2<ITechOperationDto>(url);
    return dto;
  }

  async list(
    filter: ILoopbackFilter,
    options: { signal?: AbortSignal } = {}
  ): Promise<ITechOperationDto[]> {
    // throw new Error("PORTED_TO_RTK_QUERY, still needed for farm-land-tech.tsx");
    const { signal = null } = options;
    return this.fetch<ITechOperationDto[]>(this.path, {
      method: "GET",
      searchParams: { filter: this.normalizeFilter(filter) },
      signal,
    });
  }

  async listCount(
    filter: ILoopbackFilter,
    options: { signal?: AbortSignal } = {}
  ): Promise<{ count: number }> {
    // throw new Error("PORTED_TO_RTK_QUERY, still needed for farm-land-tech.tsx");
    const { signal = null } = options;
    return this.fetch<{ count: number }>([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: filter as Record<string, unknown>,
      signal,
    });
  }

  normalizeFilter(filter: ILoopbackFilter): ILoopbackFilter {
    return {
      include: [
        { relation: "appUser", scope: { fields: ["fullName"] } },
        { relation: "crop", scope: { fields: ["name"] } },
        { relation: "farm", scope: { fields: ["name"] } },
        { relation: "farmLand", scope: { fields: ["name"] } },
        { relation: "techOperationGroup", scope: { fields: ["name"] } },
      ],
      ...filter,
    };
  }

  async getWeeklyFile(data: ICropReportFilter): Promise<void> {
    try {
      return this.downloadFileAsync("TechOpReports/getWeeklyFile", {
        searchParams: data,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  }

  //eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return super.fetch([this.path, id].join("/"), { method: "DELETE" });
  }
}

export const techOperationsService = new TechOperationsService();
