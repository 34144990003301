import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IFertilisersState } from "../shared";
import { RootState } from "../../../store-types";
import { FERTILISERS_MODULE_NAME } from "./constants";

export const getFertilisersState = (rootState: RootState): IFertilisersState =>
  rootState[FERTILISERS_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getFertilisersState,
  (state: IFertilisersState) => state.isLoading
);
export const getList = createDraftSafeSelector(
  getFertilisersState,
  (state: IFertilisersState) => state.list
);
export const getSelectedIds = createDraftSafeSelector(
  getFertilisersState,
  (state: IFertilisersState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getFertilisersState,
  (state: IFertilisersState) => state.searchText
);
