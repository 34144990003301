import { v4 as uuidv4 } from "uuid";

import {
  // ITechOperationArrayParamDto,
  // ITechOperationParamWithArrayParamsDto,
  ITechOperationValueDto,
} from "../dtos/tech-operation-param.dto";

export class TechOperationValue {
  readonly id: string;
  farmId: string;
  techOperationId: string;
  operationParamId: number; // ITechOperationParamWithArrayParamsDto["id"];
  arrayParamId: number; // ITechOperationArrayParamDto["id"];
  value: string;

  constructor(id: string | null = uuidv4()) {
    if (id === null) {
      // backend returns null here on
      // id: addTechOperationAction techOperationsService.add(data.asDto)
      this.id = uuidv4().toUpperCase();
    } else {
      this.id = id.toUpperCase();
    }
  }

  get asDto(): ITechOperationValueDto {
    return {
      id: this.id,
      farmId: this.farmId,
      techOperationId: this.techOperationId,
      operationParamId: this.operationParamId,
      arrayParamId: this.arrayParamId,
      value: this.value,
    };
  }

  updateFromDto(dto: ITechOperationValueDto): void {
    this.farmId = dto.farmId;
    this.techOperationId = dto.techOperationId;
    this.operationParamId = dto.operationParamId;
    this.arrayParamId = dto.arrayParamId;
    this.value = dto.value;
  }

  static fromDto(dto: ITechOperationValueDto) {
    const ret = new TechOperationValue(dto.id);
    ret.updateFromDto(dto);
    return ret;
  }

  static fromDto2(
    dto: ITechOperationValueDto,
    operationParamIdAsString: string,
    techOperationId: string,
    farmId: string
  ) {
    const ret = TechOperationValue.fromDto(dto);
    const operationParamId = Number.parseInt(operationParamIdAsString.split("-")[1]); // "operationParamId-8"
    ret.operationParamId = operationParamId;
    ret.techOperationId = techOperationId; // copypaste from TechOperation.updateFromFormData()
    ret.farmId = farmId; // copypaste from TechOperation.updateFromFormData()
    return ret;
  }

  get asFormData(): ITechOperationValueDto {
    return {
      id: this.id,
      arrayParamId: this.arrayParamId,
      farmId: this.farmId,
      operationParamId: this.operationParamId,
      techOperationId: this.techOperationId,
      value: this.value,
    };
  }
}
