import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../../shared/utils/rtkq";
import { MateriallyAccountableEmployee } from "../shared/models/materially-accountable-employee";
import { TMateriallyAccountableEmployeesFilter } from "../shared/interfaces/materially-accounteble-employees-filter";
import { IMateriallyAccountableEmployeeDto } from "../shared/dtos/materially-accountable-employee.dto";
import { verificationService } from "../../verification/shared/services/verification.service";
import { MateriallyAccountableEmployeeListSchema } from "../shared/dtos/materially-accountable-employee.schema";
import { toQueryString } from "../../../shared/utils/auth-token";

export const materiallyAccountableEmployeesSlice = createApi({
  reducerPath: "api-materially-accountable-employees",
  tagTypes: ["MateriallyAccountableEmployees"],
  baseQuery,
  endpoints: (build) => ({
    materiallyAccountableEmployeeList: build.query<
      MateriallyAccountableEmployee[],
      TMateriallyAccountableEmployeesFilter
    >({
      query: (filter) => ({
        url: `MateriallyAccountableEmployees?${toQueryString({ ...filter })}`,
        method: "GET",
        searchParams: toQueryString({ ...filter }), // TODO: (doesn't work) remove it in favor of base query interception
      }),
      providesTags: ["MateriallyAccountableEmployees"],
      transformResponse: (data: IMateriallyAccountableEmployeeDto[]) =>
        verificationService
          .check({
            cacheId: "MateriallyAccountableEmployeeListSchema",
            schema: MateriallyAccountableEmployeeListSchema,
            message: "Невалидный ответ сервера (MateriallyAccountableEmployees)",
          })(data)
          .map((dto) => {
            const model = new MateriallyAccountableEmployee(dto.id);
            model.updateFromDto(dto);
            return model;
          }),
    }),
  }),
});

export const { useMateriallyAccountableEmployeeListQuery } =
  materiallyAccountableEmployeesSlice;
