import { PaletteOptionsExtended } from "@mui/material/styles";

import { THEME_PRIMARY_MAIN_COLOR } from "./mui-theme-constants";
import { AssetColor } from "../enums/asset-color";

export const palette: PaletteOptionsExtended = {
  primary: {
    main: THEME_PRIMARY_MAIN_COLOR, // "#48AE4D"
    dark: "#208625",
    light: "#70D675",
    contrast: "#FFFFFF",
    hover: "#DAEFDB",
    selected: "#D1EBD3",
    focus: "#CAF3CC",
    focusVisible: "#A3D7A6",
    outlinedBorder: "#76C27A",
  },
  secondary: {
    main: "#024B24",
    dark: "#002D06",
    light: "#206942",
    contrast: "#FFFFFF",
  },
  info: {
    light: "#b9d8f1",
    main: "#5eb8ff",
    dark: "#5a8eb7",
    contrastText: "#ffffff",
  },
  error: {
    light: "#f88e86",
    main: "#f44336",
    dark: "#a2271e",
    contrastText: "#ffffff",
  },
  warning: {
    light: "#ffc166",
    main: "#ff9800",
    dark: "#bb7103",
    contrastText: "#ffffff",
  },
  success: {
    light: "#5aa257",
    main: "#82d87e",
    dark: "#b4e7b1",
    contrastText: "#ffffff",
  },
  text: {
    primary: "#464545",
    secondary: "#676767",
    disabled: "#a3a3a3",
  },
  background: {
    default: "#f5f5f5",
  },

  [AssetColor.AssetSeed]: {
    light: "#FBFEF7",
    main: "#FBFEF7",
    dark: "#FBFEF7",
    contrastText: "#000000",
  },
  [AssetColor.AssetFertiliser]: {
    light: "#F4FAFD",
    main: "#F4FAFD",
    dark: "#F4FAFD",
    contrastText: "#000000",
  },
  [AssetColor.AssetChemical]: {
    light: "#FFF8F4",
    main: "#FFF8F4",
    dark: "#FFF8F4",
    contrastText: "#000000",
  },
};
