import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { ITechOperationDto } from "../shared/dtos/tech-operation.dto";
import { devlog } from "../../../shared/utils/log";
import {
  ITechOperationParamWithArrayParamsDto,
  ITechOperationSubGroupParamWithArrayParamsDto,
} from "../shared/dtos/tech-operation-param.dto";
import { toQueryString } from "../../../shared/utils/auth-token";

export type FetchAreaHistory_forOpNumberRequest = {
  farmLandId: string;
  Real_farmLandId?: string;
  techOperationGroupId: string;
  operationNumber: number;
};

export type FetchOperationNumbers_forGroupRequest = {
  farmLandId: string;
  techOperationGroupId: string;
};

export const techOpsRelatedApiSlice = createApi({
  reducerPath: "api-techop-related",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl:
        process.env.REACT_APP_API_ENDPOINT || ".env: define REACT_APP_API_ENDPOINT",
    }),
    { maxRetries: 1 }
  ),
  endpoints: (builder) => ({
    fetchAreaHistory_forOpNo: builder.query<
      ITechOperationDto[],
      FetchAreaHistory_forOpNumberRequest
    >({
      query: (searchParams) => {
        const { farmLandId, Real_farmLandId, techOperationGroupId, operationNumber } =
          searchParams;
        devlog(
          `fetchTechOperations_ForFarmLandAndTechOperationGroupIdsAndOperationNumberAction():`,
          searchParams
        );
        const qs = toQueryString({
          filter: {
            where: {
              farmLandId,
              ...(Real_farmLandId && { Real_farmLandId: Real_farmLandId }),
              techOperationGroupId,
              operationNumber,
            },
          },
        });
        const relPath = `TechOperationAgregatedsV2?${qs}`;
        return { url: relPath, method: "GET" };
      },
    }),

    fetchOpNumbers_forGroup: builder.query<
      Map<number, ITechOperationDto>,
      FetchOperationNumbers_forGroupRequest
    >({
      query: (searchParams) => {
        const { farmLandId, techOperationGroupId } = searchParams;
        devlog(
          `getSubGroupIdByOperationNumber_ForFarmLandAndTechOperationGroupIdsAction():`,
          searchParams
        );
        const qs = toQueryString({
          filter: {
            where: {
              farmLandId,
              techOperationGroupId,
            },
            order: "operationNumber DESC",
            //IM_BUILDING_A_MAP_NOW limit: 1,
          },
        });
        const relPath = `TechOperationAgregatedsV2?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (operationNumbers: ITechOperationDto[]) => {
        const techOpByOpNo = new Map<number, ITechOperationDto>();
        for (const techOp of operationNumbers) {
          techOpByOpNo.set(techOp.operationNumber || 0, techOp);
        }
        return techOpByOpNo;
      },
    }),

    fetchSubGroupIds_forCropType: builder.query<string[], string>({
      query: (cropTypeId: string) => {
        devlog(`fetchTechOperationSubGroupIds_ForCropTypeIdAction(${cropTypeId})`);
        const qs = toQueryString({
          filter: {
            where: {
              cropTypeId,
            },
            fields: ["techOperationSubGroupId"],
          },
        });
        const relPath = `TechOperationSubGroupCropTypeRelations?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dto: Record<"techOperationSubGroupId", string>[]) => {
        return dto.map((item) => item.techOperationSubGroupId);
      },
    }),

    fetchSubGroupParams_forSubGroup: builder.query<
      ITechOperationParamWithArrayParamsDto[],
      string
    >({
      query: (subGroupId: string) => {
        devlog(`ex-fetchTechOperationSubGroupParams_ForSubGroupId(${subGroupId})`);
        const qs = toQueryString({
          filter: {
            where: {
              subGroupId: subGroupId,
            },
            include: {
              relation: "TechOperationParam",
              scope: {
                include: {
                  relation: "techOperationArrayParams",
                  scope: {
                    include: "techOperationArrayParamsDetails",
                  },
                },
              },
            },
          },
        });
        const relPath = `TechOperationSubGroupParams?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dto: ITechOperationSubGroupParamWithArrayParamsDto[]) => {
        return dto.map((item) => item.TechOperationParam);
      },
    }),
  }),
});

export const {
  useFetchAreaHistory_forOpNoQuery,
  useLazyFetchAreaHistory_forOpNoQuery,
  useLazyFetchOpNumbers_forGroupQuery,
  useLazyFetchSubGroupIds_forCropTypeQuery,
  useLazyFetchSubGroupParams_forSubGroupQuery,
} = techOpsRelatedApiSlice;

// export const useTechOpNumbersQueryState =
//   techOpsRelatedApiSlice.endpoints.fetchAreaHistory_forOpNo.useQueryState;
