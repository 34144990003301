import { API_URL } from "../../config/api";
import { BaseRestService, TAbstractFilter } from "./base-rest.service";
import { IErrorResponseDto } from "../dtos/error-response.dto";
import { parse } from "content-disposition-header";

type TExtendedRequestInit<T> = RequestInit &
  Partial<{
    searchParams: Record<string, unknown> & { filter?: T };
    baseUrl: string;
    raw: boolean;
  }>;

export abstract class BaseService<
  TDto,
  T extends TAbstractFilter,
> extends BaseRestService {
  public readonly ROOT_URL: string = process.env.REACT_APP_API_ENDPOINT || API_URL || ""; // TODO: remove const URL later

  abstract list(filter: T, include: unknown): Promise<TDto[]>;

  abstract get(id: string): Promise<TDto>;

  async fetch<TResponse = TDto, R = T>(
    path: string,
    init: TExtendedRequestInit<R> = {}
  ): Promise<TResponse> {
    const { raw = false, baseUrl = this.ROOT_URL, searchParams, ...nativeInit } = init;
    const {
      origin,
      pathname,
      searchParams: paramsFromUrl,
    } = new URL([baseUrl, path].join("/"));

    const urlWithSearch = super.getUrlWithSearch({
      url: origin + pathname,
      searchParams: paramsFromUrl,
      searchParamsObj: searchParams,
    });
    const chargedFetch = fetch(urlWithSearch, nativeInit);

    return raw
      ? chargedFetch
      : chargedFetch
          .then((response) => {
            // TODO: handle errors globally
            if (response.ok) {
              return response;
            }
            throw Error(response.statusText);
          })
          .then((response) => response.json());
  }

  async fetch2<TResponse = TDto, R = T>(
    path: string,
    init: TExtendedRequestInit<R> = { method: "GET" }
  ): Promise<TResponse> {
    const { baseUrl = this.ROOT_URL, searchParams, ...nativeInit } = init;
    const url = [baseUrl, path].join("/");

    const urlWithSearch = this.getUrlWithSearch({
      url: url,
      searchParamsObj: searchParams,
    });
    const response = await fetch(urlWithSearch, nativeInit);
    if (!response.ok) {
      const error = await response.json();
      // eslint-disable-next-line no-throw-literal
      throw error as IErrorResponseDto;
    }
    const dto = (await response.json()) as TResponse;
    return dto;
  }

  downloadFile(url: string): void {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.setAttribute("download", ""); // This attribute prompts the browser to download the file
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadFileAsync(
    url,
    options: Partial<{ filename: string; searchParams: Record<string, unknown> }> = {}
  ): Promise<void> {
    const { filename = "download.txt", ...last } = options;
    let resultFilename = filename;
    return this.fetch<Response>(url, { raw: true, ...last })
      .then((response) => {
        const contentDisposition = response.headers.get("Content-Disposition");
        if (contentDisposition) {
          const cd = parse(contentDisposition);
          resultFilename = cd.parameters.filename || resultFilename;
        }
        return response.blob();
      })
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectURL;
        a.download = resultFilename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(objectURL);
      });
  }
}
