import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { OBSERVATION_EDITING_MODULE_NAME } from "./constants";
import { IObservationEditingState } from "../shared/interfaces/types";

export const getObservationEditingState = (
  rootState: RootState
): IObservationEditingState => rootState[OBSERVATION_EDITING_MODULE_NAME];

export const getIsLoading = createDraftSafeSelector(
  getObservationEditingState,
  (state: IObservationEditingState) => state.isLoading
);

export const getOldPhotosList = createDraftSafeSelector(
  getObservationEditingState,
  (state: IObservationEditingState) => state.oldPhotos
);

export const getChosenPhotosList = createDraftSafeSelector(
  getObservationEditingState,
  (state: IObservationEditingState) => state.chosenPhotos
);
