import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { IAssetUsageRecordsState } from "../shared/interfaces/asset-usage-records-state";
import { AssetUsageRecord } from "../shared/models/asset-usage-record";
import { AssetUsageComposition } from "../shared/models/asset-usage-composition";
import { ASSET_USAGE_RECORDS_MODULE_NAME } from "./constants";

export const getAssetUsageRecordsState = (
  rootState: RootState
): IAssetUsageRecordsState => rootState[ASSET_USAGE_RECORDS_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.isLoading
);
export const getList = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.list
);
export const getListCount = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.listCount
);
export const getSelected = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.selected
);
export const getSelectedIds = createDraftSafeSelector(
  getSelected,
  (items: AssetUsageRecord[]) => items.map((item) => item.id)
);

export const getIsAllSelected = createDraftSafeSelector(
  getList,
  getSelectedIds,
  (list: AssetUsageRecord[], selectedIds: string[]) =>
    list.every((item) => selectedIds.includes(item.id))
);

// TODO: need to optimize composition selector (maybe we need to store items in state and use add/remove methods)
export const getCompositions = createDraftSafeSelector(
  getSelected,
  (selected: AssetUsageRecord[]) => {
    const composedItems = selected.reduce<{ [key: string]: AssetUsageRecord[] }>(
      (acc, item) => {
        const id = AssetUsageComposition.getItemId(item);

        if (!acc[id]) {
          acc[id] = [];
        }
        acc[id].push(item);

        return acc;
      },
      {}
    );

    return Object.values(composedItems).map((items) => new AssetUsageComposition(items));
  }
);

export const getIsFilterOpen = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.isFilterOpen
);

export const getFilter = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.filter
);

export const getVisibleColumns = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) =>
    state.configuration.columns.filter((item) =>
      state.configuration.selected.includes(item.id)
    )
);
export const getVisibleColumnIds = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.configuration.selected
);
export const getConfigurableColumns = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) =>
    state.configuration.columns.filter((item) =>
      state.configuration.configurable.includes(item.id)
    )
);

export const getDetails = createDraftSafeSelector(
  getAssetUsageRecordsState,
  (state: IAssetUsageRecordsState) => state.details
);
