import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IChemicalsState } from "../shared";
import { RootState } from "../../../store-types";
import { CHEMICALS_MODULE_NAME } from "./constants";

export const getChemicalsState = (rootState: RootState): IChemicalsState =>
  rootState[CHEMICALS_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getChemicalsState,
  (state: IChemicalsState) => state.isLoading
);
export const getList = createDraftSafeSelector(
  getChemicalsState,
  (state: IChemicalsState) => state.list
);
export const getSelectedIds = createDraftSafeSelector(
  getChemicalsState,
  (state: IChemicalsState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getChemicalsState,
  (state: IChemicalsState) => state.searchText
);
