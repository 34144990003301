import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { IVerificationState } from "../shared/interfaces/verification-state";
import { VERIFICATION_MODULE_NAME } from "./verification.slice";

export const getNotificationsState = (rootState: RootState): IVerificationState =>
  rootState[VERIFICATION_MODULE_NAME];

export const getEnabled = createDraftSafeSelector(
  getNotificationsState,
  (state) => state.enabled
);
