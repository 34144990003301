import { Box } from "@mui/system";

/*
 * TODO: add Stack component as base (instead of div) after mui5 will be implemented
 *  <Stack sx={{ width: '100%' }} spacing={2}>
 */

export const AlertWrapper = ({ children }) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      zIndex: 9999,
      margin: { m: 1 },
      left: "50%",
      transform: "translateX(-50%)",
      "& > *": {
        margin: { m: 0.2 },
      },
    }}
  >
    {children}
  </Box>
);
