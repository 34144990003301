import { useState } from "react";
import clsx from "clsx";

import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";
import { AppBar, Divider, Drawer, IconButton, Toolbar, Typography } from "@mui/material";

import { UserPanel } from "../user-panel/user-panel";
import {
  SidebarPanelLayout,
  classes,
} from "./components/sidebar-layout/sidebar-panel-layout";
import { SidebarLinks } from "./components/sidebar-links/sidebar-links";
import { bgPrimaryContrast, colorWhite } from "./styles";

export const SidebarPanel = ({ title }: { title: string }): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <SidebarPanelLayout className={classes.root}>
      <AppBar
        sx={bgPrimaryContrast}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolbar}>
          <Typography variant="h4" noWrap>
            {title}
          </Typography>
          <UserPanel />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.hamburger}>
          <IconButton onClick={() => setOpen((value) => !value)} size="large">
            {open ? <CloseIcon sx={colorWhite} /> : <MenuIcon sx={colorWhite} />}
          </IconButton>
        </div>
        <Divider />
        <SidebarLinks />
      </Drawer>
    </SidebarPanelLayout>
  );
};
