import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { FilterName } from "../shared/enums/filter-name";
import {
  IFilterDisabling,
  IFilterLoadings,
  IFilters,
  IFiltersState,
} from "../shared/interfaces/filters-state";
import { filtersService } from "../shared/services/filters.service";
import { _getFilter, getInit } from "./filters.selector";
import { addNotificationAction } from "../../notifications";
import { NotificationType } from "../../notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../notifications/shared/enums/notification-severity";
import { RootState } from "../../../store-types";
import { FILTERS_MODULE_NAME } from "./constants";

const initialState: IFiltersState = {
  init: false,
  filters: Object.values(FilterName).reduce(
    (acc, value: FilterName) => ({
      ...acc,
      [value]: null,
    }),
    {} as IFilters
  ),
  loading: Object.values(FilterName).reduce(
    (acc, value) => ({ ...acc, [value]: false }),
    {} as IFilterLoadings
  ),
  disabling: Object.values(FilterName).reduce(
    (acc, value) => ({ ...acc, [value]: false }),
    {} as IFilterDisabling
  ),
};

export const filtersSlice = createSlice({
  name: FILTERS_MODULE_NAME,
  initialState,
  reducers: {
    setInitAction(state, action: PayloadAction<boolean>): void {
      state.init = action.payload;
    },
    // TODO: fix types (value is combination of all possible filter values)
    saveFilterAction<T extends FilterName>(
      state,
      action: PayloadAction<{ filter: T; value: IFilters[T] }>
    ): void {
      const { filter, value } = action.payload;
      state.filters[filter] = value;
    },

    setFilterLoadingAction<T extends FilterName>(
      state,
      action: PayloadAction<{ filter: T; value: boolean }>
    ): void {
      const { filter, value } = action.payload;
      state.loading[filter] = value;
    },

    setFilterDisablingAction<T extends FilterName>(
      state,
      action: PayloadAction<{ filter: T; value: boolean }>
    ): void {
      const { filter, value } = action.payload;
      state.disabling[filter] = value;
    },
  },
});

export const filtersReducer = filtersSlice.reducer;
export const {
  saveFilterAction,
  setFilterLoadingAction,
  setFilterDisablingAction,
  setInitAction,
} = filtersSlice.actions;

export const fetchFiltersAction = createAsyncThunk(
  `${FILTERS_MODULE_NAME}/fetchFiltersAction`,
  async (_, { dispatch }) => {
    dispatch(setInitAction(false));
    const filters = await filtersService.list({});

    filters.forEach(({ _id: filter, value }) => {
      dispatch(saveFilterAction({ filter, value }));
    });
    dispatch(setInitAction(true));
  }
);

export const setFilterAction = createAsyncThunk<
  void,
  { filter: FilterName; value: IFilters[FilterName] },
  { state: RootState }
>(
  `${FILTERS_MODULE_NAME}/setFiltersAction`,
  async ({ filter, value }, { dispatch, getState }) => {
    const state = getState();
    const currentFilterValue = _getFilter(state, filter);
    const isInit = getInit(state);

    if (!isInit) {
      dispatch(
        addNotificationAction({
          type: NotificationType.Snack,
          severity: NotificationSeverity.Warning,
          message: `Try to set filter before initialization`,
        })
      );
      return;
    }

    try {
      if (currentFilterValue === value) {
        return;
      }
      await filtersService.addOrUpdate({ _id: filter, value });
      dispatch(saveFilterAction({ filter, value }));
    } catch (err) {
      // skip errors
    }
  }
);
