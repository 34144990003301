import { IFarmLandDto } from "../../pages/fields/shared/dtos/farm-land.dto";
import { BaseService } from "../../shared/services";
import { ILoopbackFilter } from "../filter/shared/interfaces/loopback";

class CurrentFarmLandsService extends BaseService<IFarmLandDto, ILoopbackFilter> {
  // [5:48 PM] Андрей Цуприк
  // Паш, а ты не можешь попробовать чуть по-другому запрашивать farmLands?
  // https://ekocrop-staging.ekoniva-apk.org/api/FarmLands?
  // access_token=l0VkGfAfrMPqBdiNCsKI2INSjcJt1bzwEfH7LkFCPaFukOGjyPL309VW2FtSzdVR
  // &filter={%22where%22:{
  //    %22seasonId%22:%22af4dd460-da7e-11eb-8200-6d722aac01fb%22,
  //    %22isDeleted%22:true,
  //    %20%22farmId%22:%20%22a5b9f4f0-b3ed-11ea-b650-d314e1d8e649%22
  // },
  // %22fields%22:{%22shape%22:false,%22shapeJson%22:false}}
  // can be deleted: current-farm-lands.rtkq has a fresh implementation
  async farmLands_forFarmId_includeDeleted({
    farmId,
    excludeGeometry = false,
    filter,
  }: {
    farmId: string;
    excludeGeometry: boolean;
    filter?: ILoopbackFilter;
  }): Promise<IFarmLandDto[]> {
    if (!farmId) {
      throw Error(`PASS_NON_EMPTY_STRING: farmId[${farmId}]`);
    }

    const ret = this.fetch("FarmLands", {
      method: "GET",
      searchParams: {
        filter: JSON.stringify({
          ...filter,
          fields: {
            ...filter?.fields,
            ...(excludeGeometry ? { shape: false, shapeJson: false } : {}),
          },
        }),
      },
    }) as Promise<IFarmLandDto[]>; // HACK for BaseService<> inheritance

    return ret;
  }

  // can be deleted: current-farm-lands.rtkq has a fresh implementation
  async farmLands_forFarmId({
    farmId,
    excludeGeometry = false,
    filter,
  }: {
    farmId: string;
    excludeGeometry: boolean;
    filter?: ILoopbackFilter;
  }): Promise<IFarmLandDto[]> {
    if (!farmId) {
      return [];
    }

    const ret = this.fetch(["Farms", farmId, "FarmLands"].join("/"), {
      method: "GET",
      searchParams: {
        /**
         * TODO: fix ILoopbackFilter or fix backend
         * &filter[fields][shape]=false&filter[fields][shapeJson]=false
         * do not work (returns only 'shape' and 'shapeJson' fields at models)
         **/

        filter: JSON.stringify({
          ...filter,
          fields: {
            ...filter?.fields,
            ...(excludeGeometry ? { shape: false, shapeJson: false } : {}),
          },
        }),
      },
    }) as Promise<IFarmLandDto[]>; // HACK for BaseService<> inheritance
    return ret;
  }

  async get(_: string): Promise<IFarmLandDto> {
    throw Error("INHERITED_FROM_BaseService_BY_MISUNDERSTANDING");
  }

  async list(_: ILoopbackFilter): Promise<IFarmLandDto[]> {
    throw Error("INHERITED_FROM_BaseService_BY_MISUNDERSTANDING");
  }
}

export const currentFarmLandsService = new CurrentFarmLandsService();
