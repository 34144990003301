import { ICropTypeDto } from "../dtos/crop-type";

export class CropType {
  readonly id: string;
  name: string;
  color?: string;
  basicHumidity?: number;
  basicImpurity?: number;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): ICropTypeDto {
    return {
      id: this.id,
      name: this.name,
      basicHumidity: this.basicHumidity || null,
      basicImpurity: this.basicImpurity || null,
    };
  }

  updateFromDto(dto: ICropTypeDto): void {
    this.name = dto.name;
    this.color = dto.color ?? undefined;
    this.basicHumidity = dto.basicHumidity ?? undefined;
    this.basicImpurity = dto.basicImpurity ?? undefined;
  }
}
