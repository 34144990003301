import { JSONSchemaType } from "ajv";
import { ISeedsDto } from "./seeds";
import { Units } from "../enums/units";
import { schemaNullableField } from "../../../verification/shared/utils/schema-nullable-field";

export const SeedsSchema: JSONSchemaType<ISeedsDto> = {
  type: "object",
  properties: {
    id: { type: "string" },
    name: { type: "string" },
    sortName: schemaNullableField({ type: "string" }),
    varietyName: schemaNullableField({ type: "string" }),
    assetModel: schemaNullableField({ type: "string", nullable: true }),
    assetName: { type: "string", nullable: true },
    cropTypeId: { type: "string", nullable: true },
    crops: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: { type: "string" },
        },
        required: ["id"],
      },
      nullable: true,
    },
    units: {
      type: "array",
      items: { type: "string" },
      nullable: true,
    },
    unit: {
      type: "string",
      enum: Object.values(Units),
      nullable: true,
    },

    // TODO: better to update this deprecated timestamps
    deletedAt: { type: "string", nullable: true },
    isDeleted: { type: "boolean", nullable: true },
    createdAt: { type: "string", nullable: true },
    updatedAt: { type: "string", nullable: true },
    createdByUserId: { type: "string", nullable: true },
    updatedByUserId: { type: "string", nullable: true },
  },
  required: ["id", "name"],
};

export const SeedsListSchema: JSONSchemaType<ISeedsDto[]> = {
  type: "array",
  items: SeedsSchema,
};
