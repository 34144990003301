import { Dispatch } from "redux";
import { PayloadAction } from "@reduxjs/toolkit";

import { getFeaturesAPI } from "../../utils/utils";
import { InfoDataState } from "./info-data-types";

const INFO_NAMESPACE = "@infoData";

//TODO any
export const SET_INFO_DATA = `${INFO_NAMESPACE}/SET_INFO_DATA`;

export const setInfoData = (
  info_data: Partial<InfoDataState>
): PayloadAction<Partial<InfoDataState>> => {
  return {
    type: SET_INFO_DATA,
    payload: info_data,
  };
};

export const fetchCatalogInfoData =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    await Promise.all([
      // replaced with dispatch(fetchCropTypesAction()).unwrap(),
      // getFeaturesAPI<ICropType>("CropTypes").then((cropTypes) =>
      //   dispatch(
      //     setInfoData({
      //       cropTypes: cropTypes,
      //     })
      //   )
      // ),
      getFeaturesAPI<InfoDataState["departments"]>("Departments").then((departments) =>
        dispatch(
          setInfoData({
            departments: departments,
          })
        )
      ),
      getFeaturesAPI<InfoDataState["farms"]>("Farms").then((farms) =>
        dispatch(
          setInfoData({
            farms: farms,
          })
        )
      ),
      getFeaturesAPI<InfoDataState["positions"]>("Positions").then((positions) =>
        dispatch(
          setInfoData({
            positions: positions,
          })
        )
      ),
      getFeaturesAPI<InfoDataState["seasons"]>("Seasons").then((seasons) =>
        dispatch(
          setInfoData({
            seasons:
              seasons !== undefined
                ? seasons.sort((a, b) => (a.name < b.name ? 1 : -1))
                : undefined,
          })
        )
      ),
      // getFeaturesAPI<InfoDataState["roles"]>("Seeds").then((seeds) =>
      //   dispatch(
      //     setInfoData({
      //       seeds: seeds,
      //     })
      //   )
      // ),
      getFeaturesAPI<InfoDataState["techTypes"]>("TechTypes").then((techTypes) =>
        dispatch(
          setInfoData({
            techTypes: techTypes,
          })
        )
      ),
      getFeaturesAPI<InfoDataState["techOperationGroups"]>("TechOperationGroups").then(
        (techOperationGroups) =>
          dispatch(
            setInfoData({
              techOperationGroups: techOperationGroups,
            })
          )
      ),
      getFeaturesAPI<InfoDataState["techOperationSubGroups"]>(
        "TechOperationSubGroups"
      ).then((techOperationSubGroups) =>
        dispatch(
          setInfoData({
            techOperationSubGroups: techOperationSubGroups,
          })
        )
      ),
      getFeaturesAPI<InfoDataState["workPlace"]>("WorkPlaces").then((workPlaces) =>
        dispatch(
          setInfoData({
            workPlace: workPlaces,
          })
        )
      ),
      getFeaturesAPI<InfoDataState["phenoPhases"]>("PhenoPhases").then((phenoPhases) =>
        dispatch(
          setInfoData({
            phenoPhases: phenoPhases,
          })
        )
      ),
      getFeaturesAPI<InfoDataState["techOperationsScalars"]>("TechOperationScalars").then(
        (techOperationsScalars) =>
          dispatch(
            setInfoData({
              techOperationsScalars: techOperationsScalars,
            })
          )
      ),
    ]);

    dispatch(
      setInfoData({
        techAssetCategory: ["Самоходная", "Прицепная"],
      })
    );
  };
