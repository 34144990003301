import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IVerificationState } from "../shared/interfaces/verification-state";

const initialState: IVerificationState = {
  enabled: false,
};
export const VERIFICATION_MODULE_NAME = "Verification";

export const verificationSlice = createSlice({
  name: VERIFICATION_MODULE_NAME,
  initialState,
  reducers: {
    setEnabledAction(state, action: PayloadAction<boolean>): void {
      state.enabled = action.payload;
    },
  },
});

export const verificationReducer = verificationSlice.reducer;

export const { setEnabledAction } = verificationSlice.actions;
