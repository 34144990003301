import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  CropTypeModel,
  ICropTypeDto,
  ICropTypesState,
  cropTypesService,
} from "../shared";

const initialState: ICropTypesState = {
  isLoading: false,
  list: [],
  cropTypeById: undefined,
};
export const CROP_TYPES_MODULE_NAME = "cropTypes";
export const cropTypesSlice = createSlice({
  name: CROP_TYPES_MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<CropTypeModel[]>): void {
      state.list = action.payload;
    },
    setCropTypeByIdAction(
      state,
      action: PayloadAction<Map<string, CropTypeModel>>
    ): void {
      state.cropTypeById = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCropTypesAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchCropTypesAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchCropTypesAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const cropTypesReducer = cropTypesSlice.reducer;
export const { setListAction, setCropTypeByIdAction } = cropTypesSlice.actions;

export const fetchCropTypesAction = createAsyncThunk(
  `${CROP_TYPES_MODULE_NAME}/fetchCropTypes`,
  async (params, { dispatch }) => {
    const dtos: ICropTypeDto[] = await cropTypesService.list();
    const models = dtos.map((dto) => {
      const model = new CropTypeModel(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));

    const cropTypeById = new Map(models.map((x) => [x.id, x]));
    dispatch(setCropTypeByIdAction(cropTypeById));
  }
);
