import { createAsyncThunk } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { authSliceReducer } from "./authentication/store/auth.slice";
import { appUsersReducer, fetchAppUsersAction } from "./modules/app-users/store";
import { chemicalsReducer, fetchChemicalsAction } from "./modules/chemicals/store";
import { cropsReducer, fetchCropsAction } from "./modules/crop";

import { diseasesReducer, fetchDiseasesAction } from "./modules/diseases/store";
import { employeesReducer, fetchEmployeesAction } from "./modules/employees/store";
import {
  CURRENT_FARM_LANDS_MODULE_NAME,
  currentFarmLandsReducer,
} from "./modules/farm-lands/current-farm-lands.slice";
import { farmsReducer } from "./modules/farms";
import { fertilisersReducer, fetchFertilisersAction } from "./modules/fertilisers/store";
import {
  INFO_DATA_KEY,
  fetchCatalogInfoData,
  infoDataReducer,
} from "./modules/info-data";
import { setInfoData } from "./modules/info-data/info-data-actions";
import {
  addNotificationAction,
  notificationsReducer,
} from "./modules/notifications/store/notifications.slice";
import { fetchPhenophasesAction, phenophasesReducer } from "./modules/phenophases";
import { fetchSeedsAction, seedsReducer } from "./modules/seeds";
import { fetchTechAssetsAction, techAssetsReducer } from "./modules/tech-assets";
import { fetchWeedsAction, weedsReducer } from "./modules/weeds";
import {
  CROP_ROTATION_MODULE_NAME,
  cropRotationReducer,
} from "./pages/fields/store/crop-rotation.slice";
import { farmLandsTechReducer } from "./pages/fields/store/farm-lands-tech.slice";
import { farmLandsWeatherReducer } from "./pages/fields/store/farm-lands-weather.slice";
import {
  FIELDS_MODULE_NAME,
  farmLandsReducer,
} from "./pages/fields/store/farm-lands.slice";
import { mapControlsReducer, mapReducer } from "./pages/map-page";
import { observationEditingReducer } from "./pages/observations/store/observation-editing.slice";
import {
  OBSERVATIONS_MODULE_NAME,
  observationsReducer,
} from "./pages/observations/store/observations.slice";
import {
  TECHOPERATIONS_MODULE_NAME,
  techOperationsReducer,
} from "./pages/tech-operations";
import {
  TECHOPERATION_EDITING_MODULE_NAME,
  fetchAssetTypesAction,
  techOperationEditingReducer,
} from "./pages/tech-operations/store/tech-operation-editing.slice";
import { NotificationType } from "./modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "./modules/notifications/shared/enums/notification-severity";
import { assetUsageRecordsReducer } from "./pages/asset-usage-records/store/asset-usage-records.slice";
import { VERIFICATION_MODULE_NAME, verificationReducer } from "./modules/verification";
import { fetchAllSeedsIncludeDeletedAction } from "./modules/seeds/store/seeds.slice";
import { AUTH_MODULE_NAME } from "./authentication/store/constants";
import { APP_USERS_MODULE_NAME } from "./modules/app-users/store/constants";
import {
  FARM_LANDS_TECH_MODULE_NAME,
  FARM_LANDS_WEATHER_MODULE_NAME,
} from "./pages/fields/store/constants";
import { OBSERVATION_EDITING_MODULE_NAME } from "./pages/observations/store/constants";
import { CROPS_MODULE_NAME } from "./modules/crop/store/constants";
import { FILTERS_MODULE_NAME } from "./modules/filter/store/constants";
import { ERRORS_MODULE_NAME } from "./modules/errors/store/constants";
import { ASSET_USAGE_RECORDS_MODULE_NAME } from "./pages/asset-usage-records/store/constants";
import {
  MAP_CONTROLS_MODULE_NAME,
  MAP_PAGE_MODULE_NAME,
} from "./pages/map-page/store/constants";
import { CHEMICALS_MODULE_NAME } from "./modules/chemicals/store/constants";
import { SEEDS_MODULE_NAME } from "./modules/seeds/store/constants";
import { WEEDS_MODULE_NAME } from "./modules/weeds/store/constants";
import { DISEASES_MODULE_NAME } from "./modules/diseases/store/constants";
import { EMPLOYEES_MODULE_NAME } from "./modules/employees/store/constants";
import { TECH_ASSETS_MODULE_NAME } from "./modules/tech-assets/store/constants";
import { FARMS_MODULE_NAME } from "./modules/farms/store/constants";
import { errorsReducer } from "./modules/errors/store/errors.slice";
import { FERTILISERS_MODULE_NAME } from "./modules/fertilisers/store/constants";
import { PESTS_MODULE_NAME } from "./modules/pests/store/constants";
import { NOTIFICATIONS_MODULE_NAME } from "./modules/notifications/store/constants";
import { PHENOPHASES_MODULE_NAME } from "./modules/phenophases/store/constants";
import {
  CROP_TYPES_MODULE_NAME,
  cropTypesReducer,
  fetchCropTypesAction,
} from "./modules/crop-types/store";
import { fetchFiltersAction, filtersReducer } from "./modules/filter/store/filters.slice";
import { fetchPestsAction, pestsReducer } from "./modules/pests/store";

export const dynamicReducers = {
  [AUTH_MODULE_NAME]: authSliceReducer,
  [INFO_DATA_KEY]: infoDataReducer,
  [APP_USERS_MODULE_NAME]: appUsersReducer,
  [CHEMICALS_MODULE_NAME]: chemicalsReducer,
  [CROP_ROTATION_MODULE_NAME]: cropRotationReducer,
  [CROP_TYPES_MODULE_NAME]: cropTypesReducer,
  [DISEASES_MODULE_NAME]: diseasesReducer,
  [EMPLOYEES_MODULE_NAME]: employeesReducer,
  [FARMS_MODULE_NAME]: farmsReducer,
  [CURRENT_FARM_LANDS_MODULE_NAME]: currentFarmLandsReducer,
  [FIELDS_MODULE_NAME]: farmLandsReducer,
  [FARM_LANDS_TECH_MODULE_NAME]: farmLandsTechReducer,
  [FARM_LANDS_WEATHER_MODULE_NAME]: farmLandsWeatherReducer,
  [FERTILISERS_MODULE_NAME]: fertilisersReducer,
  [MAP_CONTROLS_MODULE_NAME]: mapControlsReducer,
  [MAP_PAGE_MODULE_NAME]: mapReducer,
  [OBSERVATIONS_MODULE_NAME]: observationsReducer,
  [OBSERVATION_EDITING_MODULE_NAME]: observationEditingReducer,
  [PESTS_MODULE_NAME]: pestsReducer,
  [PHENOPHASES_MODULE_NAME]: phenophasesReducer,
  [SEEDS_MODULE_NAME]: seedsReducer,
  [TECH_ASSETS_MODULE_NAME]: techAssetsReducer,
  [TECHOPERATIONS_MODULE_NAME]: techOperationsReducer,
  [TECHOPERATION_EDITING_MODULE_NAME]: techOperationEditingReducer,
  [CROPS_MODULE_NAME]: cropsReducer,
  [FILTERS_MODULE_NAME]: filtersReducer,
  [WEEDS_MODULE_NAME]: weedsReducer,
  [ERRORS_MODULE_NAME]: errorsReducer,
  [NOTIFICATIONS_MODULE_NAME]: notificationsReducer,
  [ASSET_USAGE_RECORDS_MODULE_NAME]: assetUsageRecordsReducer,
  [VERIFICATION_MODULE_NAME]: verificationReducer,
} as const;

// TODO: remove it later
export const rootReducer = combineReducers(dynamicReducers);

export const loadDictionariesAction = createAsyncThunk<Promise<void>, void>(
  `GLOBAL/loadDictionaries`,
  async (_, { dispatch }) => {
    try {
      // ASYNCHRONOUS_LOADING
      Promise.all([
        // don't make user wait, 10Mb will land on page later using getTechAssetsLoading
        dispatch(fetchTechAssetsAction()),
        dispatch(fetchAssetTypesAction()),
        dispatch(fetchAllSeedsIncludeDeletedAction()),
      ]);

      // SYNCHRONOUS_LOADING `Загружаются справочники...` in protected-route.tsx
      await Promise.all([
        dispatch(fetchCatalogInfoData()), // TODO: refactor it use redux.thunk
        dispatch(fetchCropTypesAction()).unwrap(),
        dispatch(fetchFiltersAction()).unwrap(),
        // ** Catalogs **
        //MOVED_TO_ASYNCHRONOUS_LOADING dispatch(fetchTechAssetsAction()).unwrap(),
        dispatch(fetchEmployeesAction()).unwrap(),
        dispatch(fetchAppUsersAction()).unwrap(),
        dispatch(fetchChemicalsAction()).unwrap(),
        dispatch(fetchFertilisersAction()).unwrap(),
        dispatch(fetchSeedsAction()).unwrap(),
        dispatch(fetchCropsAction()).unwrap(),
        dispatch(fetchDiseasesAction()).unwrap(),
        dispatch(fetchWeedsAction()).unwrap(),
        dispatch(fetchPestsAction()).unwrap(),
        dispatch(fetchPhenophasesAction()).unwrap(), // network request is still sent when commented out
      ]);
      dispatch(
        setInfoData({
          initialDictionariesLoaded: true,
        })
      );
    } catch (e) {
      dispatch(
        addNotificationAction({
          type: NotificationType.Snack,
          severity: NotificationSeverity.Error,
          error: e,
          message: "При загрузке словарей возникла ошибка",
        })
      );
    }
  }
);
