import { format } from "date-fns";

import { ISO8601_DATE_FORMAT } from "../../../../constant";
import {
  ILoopbackFilter,
  Operators,
} from "../../../../modules/filter/shared/interfaces/loopback";
import { IPaginationState } from "../../../../modules/filter/shared/interfaces/pagination-types";
import { loopBackFilterAdapter } from "../../../../modules/filter/shared/utils/loopback-filter-adapter";
import { IObservationsFilter } from "../../shared/interfaces/observations-state";
import { OrderGroup } from "../../../../shared/interface";

export type ObservationsRequest = {
  paginationState: IPaginationState;
  filtersState: IObservationsFilter;
  seasonId: string; // upper (global) filter
  farmId: string; // upper (global) filter
  skipRequest: boolean;
};

export type LoopbackObservationsFilter = {
  limit: number;
  offset: number;
  order: OrderGroup;
  observationDate: string;
  seasonId: string | null;
  farmId: string | null;
  cropTypeId?: string | undefined;
  farmLandId?: string | undefined;
  phenoPhaseId?: string | undefined;
};

export function getObservationsListFilter(
  request: ObservationsRequest
): ILoopbackFilter<LoopbackObservationsFilter> {
  const { limit, offset, order } = request.paginationState;
  const { from, to, cropType, farmLandId, phenoPhaseId } = request.filtersState;
  const { seasonId, farmId } = request;

  const backendFilter = loopBackFilterAdapter<LoopbackObservationsFilter>(
    {
      limit,
      offset,
      order,
      observationDate: format(from, ISO8601_DATE_FORMAT),
      seasonId,
      farmId,
      ...(cropType !== undefined && { cropTypeId: cropType.id }),
      ...(farmLandId !== undefined && { farmLandId }),
      ...(phenoPhaseId !== undefined && { phenoPhaseId }),
    },
    {
      where: {
        and: [
          { seasonId: "eq" },
          { farmId: "eq" },
          { observationDate: "gte" },
          { observationDate: ["lte", format(to, ISO8601_DATE_FORMAT)] },
          ...(cropType?.id ? [{ cropTypeId: "eq" as Operators }] : []),
          ...(farmLandId ? [{ farmLandId: "eq" as Operators }] : []),
          ...(phenoPhaseId ? [{ phenoPhaseId: "eq" as Operators }] : []),
        ],
      },
      // optional: ["cropTypeId", "farmLandId", "phenoPhaseId"],
    }
  );
  return backendFilter;
}
