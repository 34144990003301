import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { FilterName } from "../shared/enums/filter-name";
import { IFilters, IFiltersState } from "../shared/interfaces/filters-state";
import { FILTERS_MODULE_NAME } from "./constants";

export const getFiltersState = (rootState: RootState): IFiltersState =>
  rootState[FILTERS_MODULE_NAME];

export const getInit = createDraftSafeSelector(
  getFiltersState,
  (state: IFiltersState) => state.init
);
const getFilterValue = <T extends FilterName>(state: IFiltersState, filterName: T) =>
  state.filters[filterName] as T extends FilterName ? IFilters[T] : never;
const getFilterName = (_: RootState, filterName: FilterName) => filterName;
export const _getFilter = createDraftSafeSelector(
  getFiltersState,
  getFilterName,
  getFilterValue,
  { memoizeOptions: { maxSize: 10 } } // enable multiple selector memoization (when we use it in many components)
);
export const getFilter =
  <T extends FilterName>(filterName: T) =>
  (state: RootState) =>
    _getFilter(state, filterName) as T extends FilterName ? IFilters[T] : never;

const _getFilterComposition = createDraftSafeSelector(
  getFiltersState,
  (_: RootState, filterNames: FilterName[]) => filterNames,
  (state: IFiltersState, filterNames: FilterName[]) =>
    filterNames.reduce(
      (acc, filterName) => ({
        ...acc,
        [filterName]: state.filters[filterName],
      }),
      {}
    )
);

export const getFilterComposition =
  <T extends FilterName[]>(filterNames: T) =>
  (state: RootState) =>
    _getFilterComposition(state, filterNames) as T extends FilterName[]
      ? Pick<IFilters, T[number]>
      : never;

const _getFilterLoading = createDraftSafeSelector(
  getFiltersState,
  getFilterName,
  (state: IFiltersState, filterName: FilterName) => state.loading[filterName]
);
export const getFilterLoading = (filterName: FilterName) => (state: RootState) =>
  _getFilterLoading(state, filterName);

const _getFilterDisabling = createDraftSafeSelector(
  getFiltersState,
  getFilterName,
  (state: IFiltersState, filterName: FilterName) => state.disabling[filterName]
);
export const getFilterDisabling = (filterName: FilterName) => (state: RootState) =>
  _getFilterDisabling(state, filterName);
