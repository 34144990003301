import { FormControl, MenuItem, Select, Skeleton } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { ISeason } from "../../../../info-data/shared/interfaces/season";
import { getFilterDisabling } from "../../../store/filters.selector";
import { FieldLabel } from "../../components/field-label";
import { FilterName } from "../../enums/filter-name";
import { useSeasonEffect } from "../../effects/season";
import { useSeasons } from "src/shared/hooks/seasons";

export const FilterFieldSeasonId = (): JSX.Element => {
  const {
    appFilterSeasonId,
    allSeasonsSorted,
    setAppFilterSeasonId: setCurrentSeason,
  } = useSeasons();

  const seasons: ISeason[] | undefined = useMemo(() => {
    if (allSeasonsSorted === undefined || allSeasonsSorted.length === 0) {
      return undefined;
    }
    const ret: ISeason[] = [];
    const currentSeasonIndex = allSeasonsSorted.findIndex((x) => x.isCurrent);
    if (currentSeasonIndex == -1) {
      return allSeasonsSorted;
    }

    if (currentSeasonIndex > 0) {
      const prevSeason = allSeasonsSorted[currentSeasonIndex - 1];
      ret.push(prevSeason);
    }

    const currentSeason = allSeasonsSorted[currentSeasonIndex];
    ret.push(currentSeason);

    const limitNextSeasons = 2;
    for (
      let pushed = 1, i = currentSeasonIndex + 1;
      i < allSeasonsSorted.length;
      pushed++, i++
    ) {
      const nextSeason = allSeasonsSorted[i];
      ret.push(nextSeason);
      if (pushed >= limitNextSeasons) {
        break;
      }
    }

    return ret;
  }, [allSeasonsSorted]);

  const seasonLabels = useMemo(() => {
    if (!seasons) {
      return [];
    }

    return seasons.map((item) => ({ id: item.id, title: item.name }));
  }, [seasons]);

  const defaultValue: string | null = useMemo(() => {
    const currentSeason = seasons?.find((item) => item.isCurrent);
    if (currentSeason && currentSeason.id) {
      return currentSeason.id;
    }
    if (seasons && seasons.length > 0) {
      return seasons[0].id;
    }
    return null;
  }, [seasons]);
  const isSeasonDisabled = useSelector(getFilterDisabling(FilterName.Season));

  const onChangeHandler = useCallback(
    (event) => {
      const value = event.target.value;
      setCurrentSeason(value);
    },
    [setCurrentSeason]
  );

  useSeasonEffect();

  if (!seasonLabels.length) {
    return <Skeleton width={80} height={60} />;
  }

  return (
    <FormControl variant="standard" margin="normal">
      <FieldLabel id="season-label">Сезон</FieldLabel>
      <Select
        variant="standard"
        labelId="season-label"
        id="season-select"
        defaultValue={defaultValue}
        value={appFilterSeasonId || ""}
        onChange={onChangeHandler}
        disabled={isSeasonDisabled}
      >
        {seasonLabels.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
