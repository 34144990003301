import { Alert, AlertTitle } from "@mui/material";

import { NotificationSeverity } from "../../shared/enums/notification-severity";

interface IProps {
  title: string;
  message: string;
  severity: NotificationSeverity;
  onClose: () => void;
}
export const AlertPopup = (props: IProps): JSX.Element => {
  const { title, message, onClose, severity } = props;

  return (
    <Alert severity={severity} onClose={onClose}>
      <AlertTitle>{title}</AlertTitle>
      {message}
    </Alert>
  );
};
