import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import { NotificationSeverity } from "../../shared/enums/notification-severity";

interface IProps {
  title: string;
  message: string;
  severity: NotificationSeverity;
  onClose: () => void;
  onSuccess: () => void;
}

export const ConfirmDialog = (props: IProps): React.JSX.Element => {
  const { title, message, onSuccess, onClose, severity } = props;

  const agreeHandler = () => {
    onSuccess();
    onClose();
  };

  const disagreeHandler = () => {
    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={disagreeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={disagreeHandler} color={severity}>
          Нет
        </Button>
        <Button onClick={agreeHandler} color={severity} variant={"contained"}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
};
