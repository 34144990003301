import { Order } from "src/shared/enums";

export type OrderGroup = Array<[string | number | symbol, Order]>; // first array's item is propertyName

export type Pagination = {
  limit: number;
  order: OrderGroup;
  page: number;
  offset: number;
  handleChangePage: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  handleChangeOrder: (orderGroup: OrderGroup) => void;
  handleChangeLimit: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

export type PaginationData = {
  limit: number;
  order: OrderGroup;
  page: number;
};

export const initialPagination: PaginationData = {
  limit: 10,
  order: [],
  page: 0,
};

export type PaginationHandle = PaginationData & {
  offset: number;
  handleChangePage: (_, pageNo: number) => void;
  handleChangeOrder: (orderGroup: OrderGroup) => void;
  handleChangeLimit: (event: { target: { value: string | number } }) => void;

  // paginationClicksAfterMount is a counter of each click on pagination (order/page/pageSize);
  // paginationClicksAfterMount=0 after we went away from list to another tab and came back;
  // used in list-bound effects to re-request the list on page changed; EXAMPLE:
  // const force = paginationClicksAfterMount > 0; requestPageForFilters(force);
  paginationClicksAfterMount: number;
};
