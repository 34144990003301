import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IPhenophasesState } from "../shared";
import { RootState } from "../../../store-types";
import { PHENOPHASES_MODULE_NAME } from "./constants";

export const getPhenophasesState = (rootState: RootState): IPhenophasesState =>
  rootState[PHENOPHASES_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getPhenophasesState,
  (state: IPhenophasesState) => state.isLoading
);

export const getList = createDraftSafeSelector(
  getPhenophasesState,
  (state: IPhenophasesState) => state.list
);

export const getSelectedIds = createDraftSafeSelector(
  getPhenophasesState,
  (state: IPhenophasesState) => state.selectedIds
);

export const getSearchText = createDraftSafeSelector(
  getPhenophasesState,
  (state: IPhenophasesState) => state.searchText
);
