import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { devlog } from "../../../shared/utils/log";
import { toQueryString } from "../../../shared/utils/auth-token";
// import { IFilter } from "../../../shared/interface";
// import { ITechAssetsWithIncludeDto } from "../shared/dtos/tech-assets";
// import { normalizeFilter } from "../../../shared/utils";
// import { TechAssetWithIncludeModel } from "../shared";
// import { safeSortAssetsByName } from "./techassets.slice";
// import { ILoopbackFilter } from "../../../modules/filter/shared/interfaces/loopback";
import { TechOperationAsset } from "../../../pages/tech-operations/shared/models/tech-operation-asset";
import { ITechOperationAssetDto } from "../../../pages/tech-operations/shared/dtos/tech-operation-asset.dto";
import { TechOperationAssetType } from "../../../pages/tech-operations/shared/models/tech-operation-assetType";
import { ITechOperationAssetTypeDto } from "../../../pages/tech-operations/shared/dtos/tech-operation-assetType.dto";
import { transformLoopbackErrorResponse } from "./techop-assets.rtkq.types";

export const techAssetsApiSlice = createApi({
  reducerPath: "api-tech-assets",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl:
        process.env.REACT_APP_API_ENDPOINT || ".env: define REACT_APP_API_ENDPOINT",
    }),
    { maxRetries: 1 }
  ),
  endpoints: (builder) => ({
    // fetchTechAssets: builder.query<TechAssetWithIncludeModel[], Partial<IFilter>>({
    //   query: (filter) => {
    //     devlog(`ex-listWithIncludes():`, filter);
    //     const qs = toQueryString({
    //       // copypaste from tech-assets.service.ts
    //       filter: {
    //         ...normalizeFilter(filter),
    //         include: [
    //           {
    //             relation: "farms",
    //             scope: {
    //               fields: ["id"], // backend will send 1 field instead of whole entity
    //             },
    //           },
    //           {
    //             relation: "techOperationSubGroups",
    //             scope: {
    //               fields: ["id"], // backend will send 1 field instead of whole entity
    //             },
    //           },
    //         ],
    //       },
    //     });
    //     const relPath = `TechAssets?${qs}`;
    //     return { url: relPath, method: "GET" };
    //   },
    //   transformResponse: (dtos: ITechAssetsWithIncludeDto[]) => {
    //     // copypaste from techassets.slice.ts
    //     // seems like some time ago backend was sending (null | undefined)
    //     const nullProtection = dtos.map((dto) => ({
    //       ...dto,
    //       techEngineNum: dto.techEngineNum || "",
    //       inventoryNumber: dto.inventoryNumber || "",
    //       techFactoryNum: dto.techFactoryNum || "",
    //       stateNumber: dto.stateNumber || "",
    //     }));
    //     safeSortAssetsByName(nullProtection);
    //     const models: TechAssetWithIncludeModel[] = nullProtection.map((dto) => {
    //       const model = new TechAssetWithIncludeModel(dto.id);
    //       model.updateFromDto(dto);
    //       return model;
    //     });
    //     return models;
    //   },
    // }),

    fetchAssetTypes_forTechOp: builder.query<TechOperationAssetType[], void>({
      query: () => {
        devlog(`ex-fetchTechOperationAssetTypes()`);
        const qs = toQueryString();
        const relPath = `AssetTypes?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dtos: ITechOperationAssetTypeDto[]) => {
        // copypaste from tech-operation-editing.slice.ts
        const assetTypes: TechOperationAssetType[] = dtos.map((dto) => {
          const assetType = new TechOperationAssetType();
          assetType.updateFromDto(dto);
          return assetType;
        });
        return assetTypes;
      },
    }),

    fetchTechAssets_forTechOpId: builder.query<TechOperationAsset[], string>({
      query: (techOperationId) => {
        devlog(`ex-fetchAssets_forTechOperationIdAction(${techOperationId})`);
        const qs = toQueryString({
          // copypaste from tech-operation-editing.service.ts
          filter: { where: { techOperationId } },
        });
        const relPath = `Assets?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dtos: ITechOperationAssetDto[]) => {
        // copypaste from tech-operation-editing.slice.ts
        const assets: TechOperationAsset[] = dtos.map((dto) => {
          const asset = new TechOperationAsset(dto.id);
          asset.updateFromDto(dto);
          return asset;
        });
        return assets;
      },
      transformErrorResponse: transformLoopbackErrorResponse,
    }),
  }),
});

export const { useFetchAssetTypes_forTechOpQuery, useFetchTechAssets_forTechOpIdQuery } =
  techAssetsApiSlice;

// export const fetchTechAssets_forTechOpId_querySubscription =
//   techAssetsApiSlice.endpoints.fetchTechAssets_forTechOpId.useQuerySubscription;
