import { IErrorResponseDto } from "../../../shared/dtos/error-response.dto";
import { IFilter } from "../../../shared/interface";
import { BaseService } from "../../../shared/services";
import { IRestorePasswordResponseDto } from "../dtos/restore-password-response.dto";
import { IUserDto } from "../dtos/user.dto";
import { ILoginCredentials } from "../interfaces/login-credentials";
import { ITokenResponse } from "../interfaces/token-response";
import { IValidateTokenResponse } from "../interfaces/validate-token-response";

export interface IAuthProps {
  token: string;
  userId: string;
}

class AuthService extends BaseService<IUserDto, Partial<IFilter>> {
  get(id: string): Promise<IUserDto> {
    return this.fetch(`AppUsers/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      searchParams: {
        filter: {
          include: [
            {
              relation: "roles",
              scope: {
                fields: ["id", "name"],
              },
            },
          ],
        },
      },
    });
  }

  list(_: Partial<IFilter>): Promise<IUserDto[]> {
    throw new Error(`Auth service doesn't implement list method`);
  }

  async getToken(credentials: ILoginCredentials): Promise<ITokenResponse> {
    return this.request<ITokenResponse>(`${this.ROOT_URL}/AppUsers/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(credentials),
    });
  }

  async refreshToken(props: IAuthProps): Promise<boolean> {
    const { token, userId } = props;
    const url = new URL(
      `${this.ROOT_URL}/AppUsers/:id/accessTokens/:token`.replace(
        /:\w+/g,
        (match) => ({ ":id": userId, ":token": token })[match] || match
      )
    );
    url.searchParams.append("access_token", token);
    try {
      await this.request<IValidateTokenResponse>(url.toString(), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      return true;
    } catch {
      return false;
    }
  }

  async logout(props: IAuthProps): Promise<boolean> {
    const { token } = props;
    const url = new URL(`${this.ROOT_URL}/AppUsers/logout`);
    url.searchParams.append("access_token", token);
    try {
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      return response.ok;
    } catch {
      return false;
    }
  }

  async updateUser(dto: IUserDto, token: string): Promise<IUserDto> {
    const url = new URL(`${this.ROOT_URL}/AppUsers/${dto.id}`);
    url.searchParams.append("access_token", token);
    return this.request<IUserDto>(url.toString(), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dto),
    });
  }

  async changeOwnPassword(props: { oldPassword: string; newPassword: string; token: string }): Promise<{
    userId: "string";
    success: true;
  }> {
    const { oldPassword, newPassword, token } = props;
    const url = new URL(`${this.ROOT_URL}/AppUsers/change-app-password`);
    url.searchParams.append("access_token", token);
    return this.request<{ userId: "string"; success: true }>(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ oldPassword, newPassword }),
    });
  }

  async resetPassword(props: { email: string }): Promise<IRestorePasswordResponseDto> {
    const { email } = props;
    const url = new URL(`${this.ROOT_URL}/AppUsers/requestPasswordReset`);
    url.searchParams.append("email", email);
    return this.request<IRestorePasswordResponseDto>(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  /**
   *
   * @param {string} url  - url to send
   * @param {RequestInit} init - init params
   * @throws {IErrorResponseDto}
   * @private
   */
  private async request<T>(url: string, init?: RequestInit): Promise<T> {
    const response = await fetch(url, init);
    if (!response.ok) {
      const error = await response.json();
      throw error as IErrorResponseDto;
    }
    return (await response.json()) as T;
  }
}

export const authService = new AuthService();
