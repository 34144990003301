import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { NotificationType } from "../shared/enums/notification-type";
import { INotificationsState } from "../shared/interfaces/notifications-state";
import { NOTIFICATIONS_MODULE_NAME } from "./constants";

export const getNotificationsState = (rootState: RootState): INotificationsState =>
  rootState[NOTIFICATIONS_MODULE_NAME];

export const getSystemNotifications = createDraftSafeSelector(
  getNotificationsState,
  (state) => state.systemNotifications
);
export const getConfirmNotification = createDraftSafeSelector(
  getSystemNotifications,
  (items) => items.find((item) => item.type === NotificationType.Confirm)
);
export const getAlertNotifications = createDraftSafeSelector(
  getSystemNotifications,
  (items) => items.filter((item) => item.type === NotificationType.Alert)
);

export const getSnackNotifications = createDraftSafeSelector(
  getSystemNotifications,
  (items) => items.filter((item) => item.type === NotificationType.Snack)
);
