import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ITechOperationEditingState } from "../shared/interfaces/tech-operation-editing-state";
import { TechOperation } from "../shared/models/tech-operation";
import { TechOperationAssetType } from "../shared/models/tech-operation-assetType";
import { techOperationsAssetsService } from "../shared/services/tech-operations-assets.service";
import { techOperationsService } from "../shared/services/tech-operations.service";

const initialState: ITechOperationEditingState = {
  assetTypes: undefined, // undefined when NOT YET LOADED by fetchAssetTypesAction
};

export const TECHOPERATION_EDITING_MODULE_NAME = "techOperationEditingPage";
export const techOperationEditingSlice = createSlice({
  name: TECHOPERATION_EDITING_MODULE_NAME,
  initialState: initialState,
  reducers: {
    setTechOperationAssetTypes(
      state,
      action: PayloadAction<TechOperationAssetType[]>
    ): void {
      state.assetTypes = action.payload;
    },
  },
});

export const techOperationEditingReducer = techOperationEditingSlice.reducer;
export const { setTechOperationAssetTypes } = techOperationEditingSlice.actions;

/* @deprecated */
export const fetchTechOperation_forIdAction = createAsyncThunk<TechOperation, string>(
  `${TECHOPERATION_EDITING_MODULE_NAME}/fetchTechOperation_forId`,
  async (techOperationId, { rejectWithValue }) => {
    try {
      const dto = await techOperationsService.get(techOperationId);
      const model = new TechOperation(dto.id);
      model.updateFromDto(dto);
      return model;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const addTechOperationAction = createAsyncThunk<TechOperation, TechOperation>(
//   `${TECHOPERATION_EDITING_MODULE_NAME}/addTechOperation`,
//   async (newTechOp, { dispatch }) => {
//     newTechOp.resetCreatedOnClientAt();
//     const dto = await techOperationsService.add(newTechOp.asDto);

//     // dto.TechOperationValues[0].id can be NULL here
//     const newModel = new TechOperation(dto.id);

//     newModel.updateFromDto(dto);
//     dispatch(setTechOperationData(newModel));
//     return newModel;
//   }
// );

// export const updateTechOperationAction = createAsyncThunk<TechOperation, TechOperation>(
//   `${TECHOPERATION_EDITING_MODULE_NAME}/updateTechOperation`,
//   async (updatedTechOp, { dispatch }) => {
//     const dto = await techOperationsService.update(updatedTechOp.asDto);
//     const updatedModel = new TechOperation(dto.id);
//     updatedModel.updateFromDto(dto);
//     dispatch(setTechOperationData(updatedModel));
//     return updatedModel;
//   }
// );

export const fetchAssetTypesAction = createAsyncThunk<TechOperationAssetType[], void>(
  `${TECHOPERATION_EDITING_MODULE_NAME}/fetchTechOperationAssetTypes`,
  async (_, { dispatch }) => {
    const dtos = await techOperationsAssetsService.typesList();
    const assetTypes = dtos.map((dto) => {
      const assetType = new TechOperationAssetType();
      assetType.updateFromDto(dto);
      return assetType;
    });
    dispatch(setTechOperationAssetTypes(assetTypes));
    return assetTypes;
  }
);
