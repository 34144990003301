import { BaseService } from "../../../../shared/services";
import { ISeedsDto } from "../dtos/seeds";

import { ILoopbackFilter } from "../../../filter/shared/interfaces/loopback";

class SeedsService extends BaseService<ISeedsDto, ILoopbackFilter> {
  private path = "Seeds";

  async get(): Promise<ISeedsDto> {
    throw new Error(`Not implemented`);
  }

  async delete(id: string): Promise<void> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  /**
   * This method returns ONLY seed.isDeleted=false
   * @param {string} cropId - crop identifier
   */
  async seedsForCrop(cropId: string): Promise<ISeedsDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: {
        includeCropIds: true,
        requiredCropIds: cropId,
      },
    });
  }

  async list(filter: ILoopbackFilter): Promise<ISeedsDto[]> {
    return this.fetch(this.path, {
      method: "GET",
      searchParams: { filter },
    });
  }
}

export const seedsService = new SeedsService();
