import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getInfoSeasons } from "../../modules/info-data";
import { SeasonDates } from "../types";
import { getFilter } from "../../modules/filter/store/filters.selector";
import { FilterName } from "../../modules/filter/shared/enums/filter-name";
import { ISeason } from "../../modules/info-data/shared/interfaces/season";
import { useAppDispatch } from "../../store";
import { setFilterAction } from "src/modules/filter/store/filters.slice";

export type SeasonsHook = {
  appFilterSeasonId?: string;
  appFilterSeasonResolved?: ISeason;
  appFilterSeasonNameResolved?: string;
  seasonDates: SeasonDates;
  allSeasonsSorted?: ISeason[]; // sorted when loaded (!undefined)
  setSeasonDatesForSeasonId: (seasonId: string) => void;
  setCurrentSeason: (value: string) => void;
};

export function useSeasons() {
  const dispatch = useAppDispatch();

  const appFilterSeasonId = useSelector(getFilter(FilterName.SeasonId));
  const allSeasonsSorted = useSelector(getInfoSeasons);

  const [currentSeasonResolved, setCurrentSeasonResolved] = useState<ISeason>();
  const [currentSeasonNameResolved, setCurrentSeasonNameResolved] = useState<string>();

  const [seasonDates, setSeasonDates] = useState<SeasonDates>({});

  const setSeasonDatesForSeasonId = useCallback(
    (seasonId: string) => {
      const currentSeason = allSeasonsSorted?.find((x) => x.id === seasonId);
      setCurrentSeasonNameResolved(currentSeason?.name || seasonId);
      setSeasonDates({
        startedAt: currentSeason?.startedAt,
        finishedAt: currentSeason?.finishedAt,
      });
    },
    [allSeasonsSorted]
  );

  useEffect(() => {
    if (!appFilterSeasonId) {
      return;
    }

    setSeasonDatesForSeasonId(appFilterSeasonId);
  }, [setSeasonDatesForSeasonId, appFilterSeasonId, allSeasonsSorted]);

  useEffect(() => {
    if (!appFilterSeasonId) {
      return;
    }

    const currentSeason = allSeasonsSorted?.find((x) => x.id === appFilterSeasonId);
    if (currentSeason !== undefined) {
      setCurrentSeasonResolved(currentSeason);
    }
  }, [setCurrentSeasonResolved, appFilterSeasonId, allSeasonsSorted]);

  const setAppFilterSeasonId = useCallback(
    (newSeasonGuid: string) => {
      dispatch(setFilterAction({ filter: FilterName.SeasonId, value: newSeasonGuid }));
    },
    [dispatch]
  );

  return {
    appFilterSeasonId,
    appFilterSeasonResolved: currentSeasonResolved,
    appFilterSeasonNameResolved: currentSeasonNameResolved,
    seasonDates,
    allSeasonsSorted,
    setSeasonDatesForSeasonId,
    setAppFilterSeasonId,
  };
}
