import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IPestsState } from "../shared";
import { RootState } from "../../../store-types";
import { PESTS_MODULE_NAME } from "./constants";

export const getPestsState = (rootState: RootState): IPestsState =>
  rootState[PESTS_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getPestsState,
  (state: IPestsState) => state.isLoading
);
export const getList = createDraftSafeSelector(
  getPestsState,
  (state: IPestsState) => state.list
);
export const getSelectedIds = createDraftSafeSelector(
  getPestsState,
  (state: IPestsState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getPestsState,
  (state: IPestsState) => state.searchText
);
