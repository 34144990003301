import { PropsWithChildren, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { Box, Grid2 } from "@mui/material";

import { SidebarPanel } from "../../../modules/sidebar";
import { useAppbarHeight } from "../../utils/use-app-bar-height";

interface IProps {
  title?: string;
  contentPadding?: number;
}

// TODO: try this with Route element=<commonLayout...
export const CommonLayout = (props: PropsWithChildren<IProps>): JSX.Element => {
  const { children, title = "Эко кроп", contentPadding = 1 } = props;
  const { appbarHeight } = useAppbarHeight();

  return (
    <Grid2 container sx={{ flexDirection: "row", flexWrap: "nowrap" }}>
      <Grid2>
        <SidebarPanel title={title} />
      </Grid2>

      <Grid2 size="grow">
        <Box
          component={"div"}
          sx={{
            minHeight: `${appbarHeight}px`,
          }}
        />
        <Suspense fallback={<div>Loading...</div>}>
          <Box
            sx={{
              p: contentPadding,
            }}
          >
            {children ? children : <Outlet />}
          </Box>
        </Suspense>
      </Grid2>
    </Grid2>
  );
};
