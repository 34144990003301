import { styled } from "@mui/material/styles";

import { getThemeSpacingValue } from "../../../../shared/utils/get-theme-spacing-value";

const PREFIX = "SidebarPanel";
export const drawerWidth = 185;

export const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  hide: `${PREFIX}-hide`,
  drawer: `${PREFIX}-drawer`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  toolbar: `${PREFIX}-toolbar`,
  listItemText: `${PREFIX}-listItemText`,
  hamburger: `${PREFIX}-hamburger`,
};

export const SidebarPanelLayout = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },
  [`& .${classes.appBar}`]: {
    marginLeft: getThemeSpacingValue(theme, 6) + 1,
    width: `calc(100% - calc(${theme.spacing(6)} + 1px))`,
    [theme.breakpoints.up("sm")]: {
      marginLeft: getThemeSpacingValue(theme, 7) + 1,
      width: `calc(100% - calc(${theme.spacing(7)} + 1px))`,
    },
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.hide}`]: {
    display: "none",
  },
  [`& .${classes.drawer}`]: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  [`& .${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: theme.palette.primary.main,
  },
  [`& .${classes.drawerClose}`]: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: getThemeSpacingValue(theme, 6) + 1,
    [theme.breakpoints.up("sm")]: {
      width: getThemeSpacingValue(theme, 7) + 1,
    },
    background: theme.palette.primary.main,
  },
  [`& .${classes.toolbar}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: `0 0.2px ${theme.palette.text.secondary}`,

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  [`& .${classes.hamburger}`]: {
    padding: getThemeSpacingValue(theme, 1) - 4,
  },
}));
