import { Box, CircularProgress } from "@mui/material";
import React from "react";

import { ScreenCenter } from "./ScreenCenter";

export function SpinnerCenter({
  msg,
  minHeight = `90vh`,
  size = 30,
}: {
  msg?: string;
  minHeight?: string;
  size?: number;
}): JSX.Element {
  return (
    <ScreenCenter minHeight={minHeight}>
      <CircularProgress size={size} />
      {msg && (
        <>
          <Box width={20} />
          {msg}
        </>
      )}
    </ScreenCenter>
  );
}
