import { IFilter, FilterInclude } from "../../../../shared/interface";
import { BaseService } from "../../../../shared/services";
import { TResultPaginationFilter, normalizeFilter } from "../../../../shared/utils";
import { ITechAssetsDto, ITechAssetsWithIncludeDto } from "../dtos/tech-assets";

class TechAssetsService extends BaseService<ITechAssetsDto, Partial<IFilter>> {
  private path = "TechAssets";

  async get(id: string): Promise<ITechAssetsDto> {
    return this.fetch([this.path, id].join("/"), { method: "GET" });
  }

  // eslint-disable-next-line
  async add(data: ITechAssetsDto): Promise<any> {
    return this.fetch(this.path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async update(data: ITechAssetsDto): Promise<any> {
    if (!data?.id) {
      return; // TODO: throw something
    }

    return this.fetch([this.path, data.id].join("/"), {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(data),
    });
  }

  // eslint-disable-next-line
  async delete(id: string): Promise<any> {
    return this.fetch([this.path, id].join("/"), { method: "DELETE" });
  }

  // @deprecated
  async list(filter: Partial<IFilter>): Promise<ITechAssetsDto[]> {
    return this.fetch<ITechAssetsDto[], TResultPaginationFilter>(this.path, {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    }).then((items) =>
      items.map((item) => ({
        ...item,
        techEngineNum: item.techEngineNum || "",
        inventoryNumber: item.inventoryNumber || "",
        techFactoryNum: item.techFactoryNum || "",
        stateNumber: item.stateNumber || "",
      }))
    );
  }

  async listWithIncludes(filter: Partial<IFilter>): Promise<ITechAssetsWithIncludeDto[]> {
    const ret = this.fetch<
      ITechAssetsWithIncludeDto[],
      TResultPaginationFilter & FilterInclude
    >(this.path, {
      method: "GET",
      searchParams: {
        filter: {
          ...normalizeFilter(filter),
          include: [
            {
              relation: "farms",
              scope: {
                fields: ["id"], // backend will send 1 field instead of whole entity
              },
            },
            {
              relation: "techOperationSubGroups",
              scope: {
                fields: ["id"], // backend will send 1 field instead of whole entity
              },
            },
          ],
        },
      },
    }).then((items) =>
      items.map((item) => ({
        ...item,
        techEngineNum: item.techEngineNum || "",
        inventoryNumber: item.inventoryNumber || "",
        techFactoryNum: item.techFactoryNum || "",
        stateNumber: item.stateNumber || "",
      }))
    );
    return ret;
  }

  async listCount(filter: Partial<IFilter>): Promise<{ count: number }> {
    return this.fetch([this.path, "count"].join("/"), {
      method: "GET",
      searchParams: { filter: normalizeFilter(filter) },
    });
  }
}

export const techAssetsService = new TechAssetsService();
