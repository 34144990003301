import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { IEmployeesState } from "../shared";
import { RootState } from "../../../store-types";
import { Employee } from "../shared/models/employee";
import { EMPLOYEES_MODULE_NAME } from "./constants";

export const getEmployeesState = (rootState: RootState): IEmployeesState =>
  rootState[EMPLOYEES_MODULE_NAME];

export const getLoading = createDraftSafeSelector(
  getEmployeesState,
  (state: IEmployeesState) => state.isLoading
);
export const getList = createDraftSafeSelector(
  getEmployeesState,
  (state: IEmployeesState) => state.list
);
export const getSelectedIds = createDraftSafeSelector(
  getEmployeesState,
  (state: IEmployeesState) => state.selectedIds
);
export const getSearchText = createDraftSafeSelector(
  getEmployeesState,
  (state: IEmployeesState) => state.searchText
);
export const _getEmployeeById = createDraftSafeSelector(
  getList,
  (state: RootState, itemId: string) => itemId,
  (items, id) => items.find((item) => item.id === id),
  { memoizeOptions: { maxSize: 100 } }
);
export const getEmployeeById = (id: string) => (state: RootState) =>
  _getEmployeeById(state, id) as Employee | null;
