import { Db, DbClient, IndexedDbProvider } from "@vitologi/local-db";

import { IFilterItemDto } from "../../shared/dtos/filter-item";

// TODO: move client to root
const dbClient = new DbClient({ provider: IndexedDbProvider });

const filterDb = new Db("FilterDb", {
  client: dbClient,
  migrations: [
    {
      async up(db): Promise<void> {
        await db.createCollection("filters", { keyPath: "_id" });
      },
      async down(db): Promise<void> {
        await db.dropCollection("filters");
      },
    },
  ],
});
filterDb.open();

export const filterCollection = filterDb.collection<IFilterItemDto>("filters");
