import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { IFarmLandDto } from "../../pages/fields/shared/dtos/farm-land.dto";
import { ILoopbackFilter } from "../filter/shared/interfaces/loopback";
import { initialState } from "./current-farm-lands-state";
import { currentFarmLandsService } from "./current-farm-lands.service";
import { devlog } from "../../shared/utils/log";

export const CURRENT_FARM_LANDS_MODULE_NAME = "currentFarmLands";
export const farmLandsSlice = createSlice({
  name: CURRENT_FARM_LANDS_MODULE_NAME,
  initialState,
  reducers: {
    setCurrentFarmLands_includeDeletedAction(
      state,
      action: PayloadAction<IFarmLandDto[]>
    ): void {
      state.сurrentFarmLands_includeDeleted = action.payload;
    },
    setCurrentFarmLands_nonDeletedAction(
      state,
      action: PayloadAction<IFarmLandDto[]>
    ): void {
      state.сurrentFarmLands_nonDeleted = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFarmLands_forFarmId_includeDeleted.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchFarmLands_forFarmId_includeDeleted.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchFarmLands_forFarmId_includeDeleted.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const currentFarmLandsReducer = farmLandsSlice.reducer;
const {
  setCurrentFarmLands_includeDeletedAction,
  setCurrentFarmLands_nonDeletedAction: setCurrentFarmLandsAction,
} = farmLandsSlice.actions;

// can be deleted: current-farm-lands.rtkq has a fresh implementation
export const fetchFarmLands_forFarmId_includeDeleted = createAsyncThunk<
  IFarmLandDto[],
  { farmId: string; excludeGeometry: boolean; filter?: ILoopbackFilter }
>(
  `${CURRENT_FARM_LANDS_MODULE_NAME}/fetchFarmLands_forFarmId`,
  async (data, { dispatch }) => {
    const dtos: IFarmLandDto[] =
      await currentFarmLandsService.farmLands_forFarmId_includeDeleted(data);
    dtos.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
    dispatch(setCurrentFarmLands_includeDeletedAction(dtos));

    const nonDeleted = dtos.filter((x) => x.isDeleted === false);
    if (nonDeleted.length > 0) {
      const deleted = dtos.length - nonDeleted.length;
      devlog(
        `fetchFarmLands_forFarmId: deleted[${deleted}]/[${dtos.length}]total`,
        nonDeleted
      );
    }

    dispatch(setCurrentFarmLandsAction(nonDeleted));
    return dtos;
  }
);
