export type LoopbackErrorDto = {
  data: {
    error: LoopbackError;
  };
};

export type LoopbackError = {
  class: number; // 16
  code: string; // "EREQUEST"
  lineNumber: number; // 1
  message: string; // "Conversion failed when converting from a character string to uniqueidentifier."
  name: string; //"RequestError"
  number: number; // 8169
  originalError: {
    info: {
      name: string; // "ERROR",
      event: string; // "errorMessage",
      number: number; // 8169,
      state: number; // 2,
      class: number; // 16,…
    };
    precedingErrors: string[]; // []
    procName: string; // ""
    serverName: string; // "GIS-SQL01"

    // "RequestError: Conversion failed when converting from a character string to uniqueidentifier.\n
    // at handleError(/app/node_modules/mssql/lib/tedious/request.js: 374: 15) \n
    // at Connection.emit(events.js: 314: 20) \n
    // at Connection.EventEmitter.emit(domain.js: 506: 15) \n
    // at Parser.<anonymous>(/app/node_modules/tedious/lib/connection.js: 832: 12) \n
    // at Parser.emit(events.js: 314: 20) \n
    // at Parser.EventEmitter.emit(domain.js: 506: 15) \n
    // at Parser.<anonymous>(/app/node_modules/tedious/lib/token/token - stream - parser.js: 37: 14) \n
    // at Parser.emit(events.js: 314: 20) \n
    // at Parser.EventEmitter.emit(domain.js: 506: 15) \n
    // at addChunk(/app/node_modules/tedious/node_modules/readable - stream/lib/_stream_readable.js: 298: 12) \n
    // at readableAddChunk(/app/node_modules/tedious/node_modules/readable - stream/lib/_stream_readable.js: 280: 11) \n
    // at Parser.Readable.push(/app/node_modules/tedious/node_modules/readable - stream/lib/_stream_readable.js: 241: 10) \n
    // at Parser.Transform.push(/app/node_modules/tedious/node_modules/readable - stream/lib/_stream_transform.js: 139: 32) \n
    // at doneParsing(/app/node_modules/tedious/lib/token/stream - parser.js: 122: 14) \n
    // at/app/node_modules/tedious/lib/token/infoerror - token - parser.js: 48: 5\n
    // at/app/node_modules/tedious/lib/token/infoerror - token - parser.js: 21: 19"
    stack: string;

    state: number; // 2
    statusCode: number; // 500
  };
};

export function isLoopbackErrorResponseDto(res): res is LoopbackErrorDto {
  return res && "data" in res && "error" in res.data && "message" in res.data.error;
}

export function isLoopbackError(res): res is LoopbackError {
  return (
    res &&
    "class" in res &&
    "code" in res &&
    "lineNumber" in res &&
    "message" in res &&
    "name" in res &&
    "number" in res &&
    "originalError" in res
  );
}

export function asLoopbackError(
  ex: unknown,
  whenOtherType?: string,
  prefix?: string
): string | undefined {
  if (isLoopbackError(ex)) {
    const err = (ex as LoopbackError).message;
    return `${prefix || ""}${err}`;
  }
  return whenOtherType;
}

export const transformLoopbackErrorResponse = (
  baseQueryReturnValue: unknown,
  _,
  __
): string | LoopbackError => {
  if (!isLoopbackErrorResponseDto(baseQueryReturnValue)) {
    return `NOT_A_LOOPBACK_ERROR: ${JSON.stringify(baseQueryReturnValue)}`;
  }
  const knownDto: LoopbackErrorDto = baseQueryReturnValue as unknown as LoopbackErrorDto;
  const loopbackError: LoopbackError = knownDto.data.error;
  if (!loopbackError) {
    return `ERROR_SHOULD_BE_A_JSON: ${JSON.stringify(baseQueryReturnValue)}`;
  }
  return loopbackError;
};
