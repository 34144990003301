import { Roles } from "src/shared/enums/role";
import { IconsEnum, TMenuLink } from "./types";
import { PATHS } from "src/constant";

export const MENU_LINKS: TMenuLink[] = [
  // {
  //   roles: [Roles.user, Roles.admin, Roles.manager, Roles.external],
  //   link: PATHS.DASHBOARD,
  //   icon: "DashboardIcon",
  //   primaryText: "Дашборд",
  // },

  {
    roles: [Roles.user, Roles.admin, Roles.manager, Roles.external],
    link: PATHS.MAP_PAGE,
    icon: IconsEnum.MapIcon,
    primaryText: "Карта",
  },
  {
    roles: [Roles.user, Roles.admin, Roles.manager],
    link: PATHS.TECHOPERATIONS_PAGE,
    icon: IconsEnum.TractorIcon,
    primaryText: "Техоперации",
  },
  {
    roles: [Roles.user, Roles.admin, Roles.manager],
    link: PATHS.ASSET_USAGE_RECORDS,
    icon: IconsEnum.AssetUsageRecordsIcon,
    primaryText: "Списание ТМЦ",
  },
  {
    roles: [Roles.user, Roles.admin, Roles.manager, Roles.external],
    link: PATHS.OBSERVATIONS_PAGE,
    icon: IconsEnum.NaturePeopleIcon,
    primaryText: "Осмотры",
  },
  {
    roles: [Roles.user, Roles.admin, Roles.manager],
    link: PATHS.CATALOG_PAGE,
    icon: IconsEnum.ImportContactsIcon,
    primaryText: "Справочники",
  },
  {
    roles: [Roles.user, Roles.admin, Roles.manager, Roles.external],
    link: PATHS.SETTINGS,
    icon: IconsEnum.SettingsIcon,
    primaryText: "Настройки",
  },
];
