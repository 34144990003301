import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Order } from "../../../shared/enums/order";
import { ICropRotationState } from "../shared/interfaces/crop-rotation-state";
import { CropRotation } from "../shared/models/crop-rotation";
import { farmLandsService } from "../shared/services/farm-lands.service";

export const CROP_ROTATION_MODULE_NAME = "cropRotation";
export const cropRotationSlice = createSlice({
  name: CROP_ROTATION_MODULE_NAME,
  initialState: {
    isLoading: false,
    filter: {
      farmLandId: null,
    },
    list: [],
  } as ICropRotationState,
  reducers: {
    setLoadingAction(state, action): void {
      state.isLoading = action.payload as boolean;
    },
    setFarmLandIdAction(state, action): void {
      state.filter.farmLandId = action.payload as string | null;
    },
    setListAction(state, action): void {
      state.list = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListActionById.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchListActionById.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchListActionById.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const cropRotationReducer = cropRotationSlice.reducer;
export const { setFarmLandIdAction, setLoadingAction, setListAction } = cropRotationSlice.actions;

// async actions
export const fetchListActionById = createAsyncThunk<void, string | null>(
  `${CROP_ROTATION_MODULE_NAME}/fetchListAction`,
  async (id, { dispatch }) => {
    dispatch(setFarmLandIdAction(id));

    if (id === null) {
      dispatch(setListAction([]));
      return;
    }

    const dtos = await farmLandsService.cropRotationList(id, {
      order: [["year", Order.DESC]],
    });
    const models = dtos.map((dto) => {
      const model = new CropRotation();
      model.updateFromDto(dto);
      return model;
    });

    dispatch(setListAction(models));
  }
);
