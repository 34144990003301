export const catalogTabpanelStyles = () => ({
  root: { background: "background.paper", flex: 1 },
  inputSearch: {
    "& > *": {
      margin: { m: 1 },
      width: "25ch",
    },
    display: "flex",
    justifyContent: "space-between",
  },
  manualHeader: {
    height: 40,
    color: "text.secondary",
    fontSize: 20,
  },
  manualFooter: {
    bottom: 0,
    height: 70,
    fontWeight: 600,
    padding: { p: [2, 1] },
    borderTop: "1px solid #d9d9d9",
  },
});
