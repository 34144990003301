import { FeatureCollection, Geometry } from "geojson";
import { GeoJSON } from "leaflet";

import { IGeoFeatureLayerDto } from "../../../../shared/dtos/geo-feature-layer.dto";
import { ArcGisFormat } from "../../../../shared/interface/arcgis-filter";
import { BaseArcgisService } from "../../../../shared/services/base-arcgis.service";
import { TArcgisBriefCropPropsDto } from "../dtos/arcgis-brief-crop-props.dto";
import { TArcgisMapCropPropsDto } from "../dtos/arcgis-map-crop-props.dto";
import { TArcgisSearchCropPropsDto } from "../dtos/arcgis-search-crop-props.dto";
import { ArcgisCropField } from "../enums/arcgis-crop-field";
import { arcgisLayerToProps } from "../utils/arcgis-layer-to-props";

export type TBriefCropGeoJson = GeoJSON<TArcgisBriefCropPropsDto>; // TODO: change on Feature, geoJson construct inside reducers

export type CropMap = FeatureCollection<Geometry, TArcgisMapCropPropsDto>;

class ArcgisCropsService extends BaseArcgisService {
  static myInstance?: ArcgisCropsService;
  static getInstance(): ArcgisCropsService {
    if (!ArcgisCropsService.myInstance) {
      ArcgisCropsService.myInstance = new ArcgisCropsService();
    }
    return ArcgisCropsService.myInstance;
  }

  // https://maps.ekoniva-apk.org/arcgis/rest/services/test/ekocrop/FeatureServer/0/query?where=...
  protected path = `ekocrop/FeatureServer/0`;

  getSearchProps({
    nameLike,
    season,
    farm,
  }: {
    nameLike: string;
    season: number;
    farm: string;
  }): Promise<TArcgisSearchCropPropsDto[]> {
    return this.list<IGeoFeatureLayerDto<TArcgisSearchCropPropsDto>>({
      where: `${ArcgisCropField.name} LIKE '%${nameLike}%' AND ${ArcgisCropField.season}=${season} AND ${ArcgisCropField.farm}='${farm}'`,
      outFields: [
        ArcgisCropField.GDB_ARCHIVE_OID,
        ArcgisCropField.name,
        ArcgisCropField.farm,
        ArcgisCropField.season,
      ].join(","),
      f: ArcGisFormat.Json,
    }).then((layer) => arcgisLayerToProps<TArcgisSearchCropPropsDto>(layer));
  }
}

// BaseArcgisService will return ArcGisError without setToken();
// singleton is safe to use from multiple import{}s;
export const arcgisCropsService = ArcgisCropsService.getInstance();
