import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { ITechAssetsState } from "../shared";
import { RootState } from "../../../store-types";
import { TECH_ASSETS_MODULE_NAME } from "./constants";

export const getTechAssetsState = (rootState: RootState): ITechAssetsState =>
  rootState[TECH_ASSETS_MODULE_NAME];

export const getTechAssetsLoading = createDraftSafeSelector(
  getTechAssetsState,
  (state: ITechAssetsState) => state.isLoading
);

export const getTechAssetsList = createDraftSafeSelector(
  getTechAssetsState,
  (state: ITechAssetsState) => state.list
);

export const getTechAssetsListWithInclude = createDraftSafeSelector(
  getTechAssetsState,
  (state: ITechAssetsState) => state.listWithInclude
);

export const getSelectedIds = createDraftSafeSelector(
  getTechAssetsState,
  (state: ITechAssetsState) => state.selectedIds
);

export const getSearchText = createDraftSafeSelector(
  getTechAssetsState,
  (state: ITechAssetsState) => state.searchText
);
