import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ITechAssetsDto,
  ITechAssetsWithIncludeDto,
  ITechAssetsState,
  TechAssetWithIncludeModel,
  techAssetsService,
  TechAssetModel,
} from "../shared";
import { TECH_ASSETS_MODULE_NAME } from "./constants";

const initialState: ITechAssetsState = {
  isLoading: false,
  list: [],
  listWithInclude: [],
  selectedIds: [],
  searchText: "",
};
export const techAssetsSlice = createSlice({
  name: TECH_ASSETS_MODULE_NAME,
  initialState,
  reducers: {
    setListAction(state, action: PayloadAction<TechAssetModel[]>): void {
      state.list = action.payload;
    },
    setListWithIncludeAction(
      state,
      action: PayloadAction<TechAssetWithIncludeModel[]>
    ): void {
      state.listWithInclude = action.payload;
    },
    setSelectedIdsAction(state, action: PayloadAction<string[]>): void {
      state.selectedIds = action.payload;
    },
    toggleId(state, action: PayloadAction<string>): void {
      const id = action.payload;
      if (state.selectedIds.includes(id)) {
        state.selectedIds = state.selectedIds.filter((item) => item !== id);
      } else {
        state.selectedIds = [id, ...state.selectedIds];
      }
    },
    setSearchText(state, action: PayloadAction<string>): void {
      state.searchText = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTechAssetsAction.fulfilled, (state, _) => {
        state.isLoading = false;
      })
      .addCase(fetchTechAssetsAction.pending, (state, _) => {
        state.isLoading = true;
      })
      .addCase(fetchTechAssetsAction.rejected, (state, _) => {
        state.isLoading = false;
      });
  },
});

export const techAssetsReducer = techAssetsSlice.reducer;
export const {
  setListAction,
  setListWithIncludeAction,
  setSelectedIdsAction,
  toggleId,
  setSearchText,
} = techAssetsSlice.actions;

export const fetchTechAssetsAction = createAsyncThunk(
  `${TECH_ASSETS_MODULE_NAME}/fetchTechAssetsAction`,
  async (params, { dispatch }) => {
    const dtos: ITechAssetsWithIncludeDto[] = await techAssetsService.listWithIncludes(
      {}
    );
    safeSortAssetsByName(dtos);
    const models: TechAssetWithIncludeModel[] = dtos.map((dto) => {
      const model = new TechAssetWithIncludeModel(dto.id);
      model.updateFromDto(dto);
      return model;
    });
    dispatch(setListAction(models));
    dispatch(setListWithIncludeAction(models));
  }
);

export const deleteTechAssetsAction = createAsyncThunk<void, string[]>(
  `${TECH_ASSETS_MODULE_NAME}/deleteTechAssetsAction`,
  async (ids, { rejectWithValue }) => {
    const selectedToDeletePromises = ids.map((id) => techAssetsService.delete(id));

    try {
      await Promise.all(selectedToDeletePromises);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export function safeSortAssetsByName(dtos: ITechAssetsDto[]): void {
  dtos.sort((a, b) => {
    try {
      if (a === null || b === null) {
        return 0; // never happened (insert your breakpoint here)
      }
      const ret = a.name.localeCompare(b.name);
      return ret;
    } catch (_) {
      // console.error(`safeSortAssetsByName: [e, a, b]`, [e, a, b]);
      return 0;
    }
  });
}
