import { PropsWithChildren } from "react";

export type CatalogTabLayoutProps = PropsWithChildren<{
  type: CATALOG_SECTIONS_ENUM;
  selectedCount: number;
  onCancel: () => void; // TODO: move not common logic out from layout component (components haven't had states)
  onDelete: () => void;
  onSearch?: (value: string) => void;
}>;

export type CatalogSectionInfo = {
  [key in CATALOG_SECTIONS_ENUM]: {
    name: string;
    btnAddName: string;
  };
};

export enum CATALOG_SECTIONS_ENUM {
  TECHNICKS = "TechAssets", // данные по технике
  PERSONALS = "Employees", //"AppUsers", // данные по персоналу
  APPUSERS = "AppUsers", //"AppUsers", // данные по персоналу
  SZR = "Chemicals", // данные по СЗР
  FERTILISER = "Fertilisers", // данные по удобрениям
  SEEDS = "Seeds", // данные по семенам
  CROPS = "Crops", // данные по культурам
  PHENOPHASE = "PhenoPhases", // данные по сорнякам
  WEEDS = "Weeds", // данные по сорнякам
  PESTS = "Pests", // данные по вредителям
  DISEASES = "Diseases", // данные по болезням
}
