export type TechOperationAgregatedsV2ErrorResponseDto = {
  data: {
    error: TechOperationAgregatedsV2Error;
  };
};

export type TechOperationAgregatedsV2Error = {
  message: string; // "Не найден экземпляр TechOperationAgregatedV2"
  name: string; // "Error",

  // "Error: Не найден экземпляр TechOperationAgregatedV2\n"
  //"    at Function.TechOperationAgregatedV2.findModelById (/app/common/models/tech-operation-agregated.v2.js:224:13)"
  stack: string;

  statusCode?: number; // 500,
  code?: string;
};
function isTechOperationAgregatedsV2ErrorResponseDto(
  res
): res is TechOperationAgregatedsV2ErrorResponseDto {
  return res && "data" in res;
}

export function isTechOperationAgregatedsV2Error(
  res
): res is TechOperationAgregatedsV2Error {
  return (
    res &&
    "message" in res &&
    "name" in res &&
    // "code" in res &&
    // "statusCode" in res &&
    "stack" in res
  );
}

export function asTechOperationAgregatedsV2Error(
  ex: string | TechOperationAgregatedsV2Error,
  whenOtherType?: string,
  prefix?: string
): string | undefined {
  if (typeof ex === "string") {
    return `${prefix || ""}${ex}`;
  }
  if (isTechOperationAgregatedsV2Error(ex)) {
    const err = ex.message;
    return `${prefix || ""}${err}`;
  }
  return whenOtherType;
}

export const transformAgregatedsV2ErrorResponse = (
  baseQueryReturnValue: unknown,
  _,
  __
): string | TechOperationAgregatedsV2Error => {
  if (!isTechOperationAgregatedsV2ErrorResponseDto(baseQueryReturnValue)) {
    return `NOT_A_V2_AGREGATEDS_ERROR: ${JSON.stringify(baseQueryReturnValue)}`;
  }
  const knownDto: TechOperationAgregatedsV2ErrorResponseDto = baseQueryReturnValue;
  const endpointError: TechOperationAgregatedsV2Error = knownDto.data?.error;
  if (!endpointError) {
    return `ERROR_SHOULD_BE_A_JSON: ${JSON.stringify(baseQueryReturnValue)}`;
  }
  return endpointError;
};
