import { DefaultSchemaValue } from "../../../../modules/verification/shared/enums/default-schema-value";
import { JSONSchemaType } from "ajv";
import { IAssetUsageRecordDto } from "./asset-usage-record.dto";
import { AssetType } from "../enums/asset-type";
import { AssetUsageRecordStatus } from "../enums/asset-usage-record-status";
import { schemaNullableField } from "../../../../modules/verification/shared/utils/schema-nullable-field";

export const AssetUsageRecordSchema: JSONSchemaType<IAssetUsageRecordDto> = {
  type: "object",
  properties: {
    id: { type: "string", default: DefaultSchemaValue.STRING },
    status: {
      type: "string",
      enum: Object.values(AssetUsageRecordStatus),
      default: AssetUsageRecordStatus.Created,
    },
    detailedStatus: schemaNullableField({ type: "string" }),
    updatedAt: schemaNullableField({ type: "string", format: "iso-date-time" }),
    updatedByUserId: schemaNullableField({ type: "string" }),
    techOperation: {
      type: "object",
      properties: {
        id: { type: "string", default: DefaultSchemaValue.STRING },
        startDate: {
          type: "string",
          format: "date",
          default: DefaultSchemaValue.ISO8601_DATE_FORMAT,
        },
        finishDate: schemaNullableField({ type: "string", format: "date" }),
        fieldSize: { type: "number", default: DefaultSchemaValue.NUMBER },
        comment: schemaNullableField({ type: "string" }),
        agriculturalWorkDate: {
          type: "string",
          format: "date",
          default: DefaultSchemaValue.ISO8601_DATE_FORMAT,
        },
        techOperationGroup: {
          type: "object",
          properties: {
            id: { type: "string", default: DefaultSchemaValue.STRING },
            name: { type: "string", default: DefaultSchemaValue.STRING },
          },
          required: ["id", "name"],
        },
        techOperationSubGroup: {
          type: "object",
          properties: {
            id: { type: "string", default: DefaultSchemaValue.STRING },
            name: { type: "string", default: DefaultSchemaValue.STRING },
          },
          required: ["id", "name"],
        },
        techAsset: {
          type: "object",
          properties: {
            id: {
              type: "string",
              default: DefaultSchemaValue.STRING,
            },
            name: {
              type: "string",
              default: DefaultSchemaValue.STRING,
            },
          },
          required: ["id", "name"],
        },
        trailerAsset: schemaNullableField({
          type: "object",
          properties: {
            id: { type: "string" },
            name: { type: "string" },
          },
          required: ["id", "name"],
        }),
        operatorEmployee: {
          type: "object",
          properties: {
            id: { type: "string", default: DefaultSchemaValue.STRING },
            name: { type: "string", default: DefaultSchemaValue.STRING },
          },
          required: ["id", "name"],
        },
        createdByUser: {
          type: "object",
          properties: {
            id: { type: "string", default: DefaultSchemaValue.STRING },
            name: { type: "string", default: DefaultSchemaValue.STRING },
          },
          required: ["id", "name"],
        },
        materiallyAccountableEmployees: {
          type: "object",
          properties: {
            seeds: schemaNullableField({
              type: "object",
              properties: {
                id: { type: "string" },
                name: { type: "string" },
              },
              required: ["id", "name"],
            }),
            chemicals: schemaNullableField({
              type: "object",
              properties: {
                id: { type: "string" },
                name: { type: "string" },
              },
              required: ["id", "name"],
            }),
            fertilisers: schemaNullableField({
              type: "object",
              properties: {
                id: { type: "string" },
                name: { type: "string" },
              },
              required: ["id", "name"],
            }),
          },
          required: ["seeds", "chemicals", "fertilisers"],
        },
        assets: {
          type: "array",
          items: {
            type: "object",
            properties: {
              id: { type: "string", default: DefaultSchemaValue.STRING },
              name: { type: "string", default: DefaultSchemaValue.STRING },
              quantity: { type: "number", default: DefaultSchemaValue.NUMBER },
              unit: { type: "string", default: DefaultSchemaValue.STRING },
            },
            required: ["id", "name", "quantity", "unit"],
          },
        },
      },
      required: [
        "id",
        "startDate",
        "finishDate",
        "fieldSize",
        "comment",
        "agriculturalWorkDate",
        "techOperationGroup",
        "techOperationSubGroup",
        "techAsset",
        "trailerAsset",
        "operatorEmployee",
        "createdByUser",
        "materiallyAccountableEmployees",
      ],
    },
    farmland: {
      type: "object",
      properties: {
        id: { type: "string", default: DefaultSchemaValue.STRING },
        name: { type: "string", default: DefaultSchemaValue.STRING },
        farmId: { type: "string", default: DefaultSchemaValue.STRING },
      },
      required: ["id", "name", "farmId"],
    },
    cropType: {
      type: "object",
      properties: {
        id: { type: "string", default: DefaultSchemaValue.STRING },
        name: { type: "string", default: DefaultSchemaValue.STRING },
      },
      required: ["id", "name"],
    },
    materiallyAccountableEmployee: {
      type: "object",
      properties: {
        id: { type: "string", default: DefaultSchemaValue.STRING },
        name: { type: "string", default: DefaultSchemaValue.STRING },
      },
      required: ["id", "name"],
    },
    asset: {
      type: "object",
      properties: {
        id: { type: "string", default: DefaultSchemaValue.STRING },
        name: { type: "string", default: DefaultSchemaValue.STRING },
        quantity: { type: "number", default: DefaultSchemaValue.NUMBER },
        categoryName: {
          type: "string",
          enum: Object.values(AssetType),
          default: AssetType.SEED,
        },
        area: { type: "number", default: DefaultSchemaValue.NUMBER },
        unit: { type: "string", default: DefaultSchemaValue.STRING },
        usageRate: { type: "number", default: DefaultSchemaValue.NUMBER },
      },
      required: ["id", "name", "quantity", "categoryName", "area", "unit", "usageRate"],
    },
  },
  required: [
    "id",
    "status",
    "techOperation",
    "farmland",
    "cropType",
    "materiallyAccountableEmployee",
    "asset",
    "updatedAt",
    "updatedByUserId",
  ],
};

export const AssetUsageRecordListSchema: JSONSchemaType<IAssetUsageRecordDto[]> = {
  type: "array",
  items: AssetUsageRecordSchema,
};
