import { cloneDeep } from "lodash";
import { IAssetUsageRecordDto } from "../dtos/asset-usage-record.dto";
import { AssetType } from "../enums/asset-type";
import { AssetUsageRecordStatus } from "../enums/asset-usage-record-status";

export class AssetUsageRecord {
  /** Store dto as is into model for further usage */
  get dto(): IAssetUsageRecordDto {
    if (this._dto === null) {
      throw new Error("AssetUsage model is not defined");
    }

    return this._dto;
  }

  private _dto: IAssetUsageRecordDto | null = null;

  get compositionId(): string {
    return this.assetType + this.assetName + this.cropTypeId;
  }

  get assetType(): AssetType {
    return this.dto.asset.categoryName || AssetType.SEED; // TODO: contracts
  }

  get techOperationAssets(): IAssetUsageRecordDto["techOperation"]["assets"] {
    return this.dto.techOperation.assets || [];
  }

  get assetName(): string {
    return this.dto.asset.name || ""; // TODO: contracts
  }

  get farmLandId(): string {
    return this.dto.farmland.id;
  }
  get farmLandName(): string {
    return this.dto.farmland.name || ""; // TODO: check on backend
  }
  get techOperationGroupName(): string {
    return this.dto.techOperation.techOperationGroup.name;
  }
  get techOperationSubGroupId(): string {
    return this.dto.techOperation.techOperationSubGroup.id;
  }
  get techOperationSubGroupName(): string {
    return this.dto.techOperation.techOperationSubGroup.name || ""; // TODO: check on backend
  }
  get personCreatedId(): string {
    return this.dto.techOperation.createdByUser.id;
  }
  get personCreatedName(): string {
    return this.dto.techOperation.createdByUser.name || ""; // TODO: check on backend
  }
  get personResponsibleId(): string {
    return this.dto.materiallyAccountableEmployee.id;
  }
  get personResponsibleName(): string {
    return this.dto.materiallyAccountableEmployee.name || ""; // TODO: check on backend
  }
  get techAssetId(): string {
    return this.dto.techOperation.techAsset.id;
  }
  get techAssetName(): string {
    return this.dto.techOperation.techAsset.name || ""; // TODO: check on backend
  }
  get trailerAssetName(): string {
    return this.dto.techOperation.trailerAsset?.name || ""; // TODO: check on backend
  }
  get quantity(): number {
    return this.dto.asset.quantity;
  }
  get unit(): string {
    return this.dto.asset.unit;
  }
  get quantityTitle(): string {
    return `${this.quantity} ${this.unit}`;
  }
  get fieldSize(): number {
    return this.dto.asset.area;
  }
  get norma(): number {
    return this.dto.asset.usageRate;
  }
  get cropTypeId(): string {
    return this.dto.cropType.id;
  }
  get cropTypeName(): string {
    return this.dto.cropType.name || ""; // TODO: check on backend
  }
  get techOperationId(): string {
    return this.dto.techOperation.id;
  }
  get status(): AssetUsageRecordStatus {
    return this.dto.status || AssetUsageRecordStatus.Created; // TODO: check contracts
  }
  get comment(): string {
    return this.dto.techOperation.comment || ""; // TODO: check on backend
  }
  get dateCreated(): Date {
    return new Date(this.dto.techOperation.agriculturalWorkDate);
  }
  get dateStart(): Date {
    return new Date(this.dto.techOperation.startDate);
  }
  get dateFinish(): Date {
    return new Date(this.dto.techOperation.finishDate || new Date());
  }

  get employeeId(): string {
    return this.dto.techOperation.operatorEmployee.id;
  }
  get employeeName(): string {
    return this.dto.techOperation.operatorEmployee.name || ""; // TODO: check on backend
  }

  get trailerAssetId(): string {
    return this.dto.techOperation?.trailerAsset?.id || ""; // TODO: check on backend
  }

  constructor(public readonly id: string) {
    this.id = id;
  }

  setStatus(status: AssetUsageRecordStatus): void {
    this.dto.status = status;
  }

  updateFromDto(dto: IAssetUsageRecordDto): void {
    // TODO: change structure on flat one, when dto will be stable
    this._dto = cloneDeep(dto);
  }

  get asDto(): IAssetUsageRecordDto {
    return cloneDeep(this.dto);
  }
}
