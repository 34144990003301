import { BaseService } from "../../../../shared/services";
import { filterCollection } from "../../offline/db/filter.db";
import { IFilterItemDto } from "../dtos/filter-item";

// eslint-disable-next-line
class FiltersService extends BaseService<IFilterItemDto, Record<string, any>> {
  async get(_: string): Promise<IFilterItemDto> {
    throw new Error("Cant be used");
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  async list(filter: Record<string, any>): Promise<IFilterItemDto[]> {
    return await filterCollection.find(filter);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  async addOrUpdate(data: IFilterItemDto): Promise<any> {
    const { _id, value } = data;

    if (!_id) {
      return; // TODO: throw something;
    }

    const item = await filterCollection.findOne({ _id });
    let result;
    if (!item) {
      result = await filterCollection.insertOne({ _id, value });
    } else if (item.value !== value) {
      result = await filterCollection.updateOne({ _id }, { _id, value }, { bypassDocumentValidation: true });
    } else {
      result = {};
    }

    return result;
  }
}

export const filtersService = new FiltersService();
