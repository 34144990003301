import { IFarmsDto } from "../dtos/farms";

export class Farm {
  id: string;
  name: string;
  isAssetsWriteOffEnabled: boolean;

  get asDto(): IFarmsDto {
    return {
      id: this.id,
      name: this.name,
      isAssetsWriteOffEnabled: this.isAssetsWriteOffEnabled,
    };
  }

  updateFromDto(dto: IFarmsDto): void {
    this.id = dto.id;
    this.name = dto.name;
    this.isAssetsWriteOffEnabled = dto.isAssetsWriteOffEnabled;
  }
}
