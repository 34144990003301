import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { devlog } from "../../shared/utils/log";
import { toQueryString } from "../../shared/utils/auth-token";
import { IFarmLandDto } from "../../pages/fields/shared/dtos/farm-land.dto";
import { ILoopbackFilter } from "../filter/shared/interfaces/loopback";

export type FetchFarmLands_forFarmId_Request = {
  farmId: string;
  seasonId: string;
};

export const currentFarmLandsApiSlice = createApi({
  reducerPath: "api-farm-lands-related",
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl:
        process.env.REACT_APP_API_ENDPOINT || ".env: define REACT_APP_API_ENDPOINT",
    }),
    { maxRetries: 1 }
  ),
  endpoints: (builder) => ({
    fetchFarmLands_forFarmId_includeDeleted: builder.query<
      IFarmLandDto[],
      FetchFarmLands_forFarmId_Request
    >({
      query: (searchParams) => {
        devlog(`fetchFarmLands_forFarmId_includeDeleted()`, searchParams);
        const qs = toListQueryString(searchParams);
        const relPath = `FarmLands?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dtos: IFarmLandDto[]) => {
        dtos.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
        selectNonDeletedOnly(
          dtos,
          `fetchFarmLands_forFarmId_includeDeleted() JUST_INFO: `
        );
        return dtos;
      },
    }),

    fetchFarmLands_forFarmId_excludeDeleted: builder.query<
      IFarmLandDto[],
      FetchFarmLands_forFarmId_Request
    >({
      query: (searchParams) => {
        devlog(`fetchFarmLands_forFarmId_excludeDeleted()`, searchParams);
        const qs = toListQueryString(searchParams);
        const relPath = `FarmLands?${qs}`;
        return { url: relPath, method: "GET" };
      },
      transformResponse: (dtos: IFarmLandDto[]) => {
        dtos.sort((a, b) => (a.name < b.name ? -1 : b.name < a.name ? 1 : 0));
        const nonDeleted = selectNonDeletedOnly(
          dtos,
          `fetchFarmLands_forFarmId_excludeDeleted() `
        );
        return nonDeleted;
      },
    }),
  }),
});

export function selectNonDeletedOnly(
  dtos: IFarmLandDto[],
  logPrefix?: string
): IFarmLandDto[] {
  const nonDeleted = dtos.filter((x) => x.isDeleted === false);
  if (nonDeleted.length > 0) {
    const deleted = dtos.length - nonDeleted.length;

    if (logPrefix) {
      devlog(
        `${logPrefix}` +
          `nonDeleted[${nonDeleted.length}]` +
          `:deleted[${deleted}]` +
          `/[${dtos.length}]total`,
        nonDeleted
      );
    }
  }
  return nonDeleted;
}

function toListQueryString(
  searchParams: FetchFarmLands_forFarmId_Request,
  excludeGeometry = true
) {
  const { farmId, seasonId } = searchParams;

  const filter: ILoopbackFilter = {
    where: {
      seasonId: seasonId,
      farmId: farmId,
      // isDeleted: false,
      // isDeleted: true,
      isDeleted: { neq: null },
    },
  };

  const qs = toQueryString({
    filter: JSON.stringify({
      ...filter,
      fields: {
        ...filter?.fields,
        ...(excludeGeometry ? { shape: false, shapeJson: false } : {}),
      },
    }),
  });
  return qs;
}

export const {
  useFetchFarmLands_forFarmId_includeDeletedQuery,
  useLazyFetchFarmLands_forFarmId_includeDeletedQuery,
  useFetchFarmLands_forFarmId_excludeDeletedQuery,
  useLazyFetchFarmLands_forFarmId_excludeDeletedQuery,
} = currentFarmLandsApiSlice;
