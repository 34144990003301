import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { fetchFarmsAction, getList, getLoading } from "src/modules/farms/store";
import { getFilter } from "src/modules/filter/store/filters.selector";
import { FilterName } from "src/modules/filter/shared/enums/filter-name";

export const useHideAssetsWriteOff = () => {
  const dispatch = useAppDispatch();
  const isLoading = useSelector(getLoading);
  const allFarms = useSelector(getList);
  const farmId = useSelector(getFilter(FilterName.FarmId));

  useEffect(() => {
    if (!allFarms.length && !isLoading) {
      dispatch(fetchFarmsAction()); // this is temporary, farmactions must be in the store. A. Morozov can better describe the task
    }
  }, [dispatch, allFarms, isLoading]);

  const isAssetUsageRecordsEnabled = useMemo(
    () => !!allFarms.find((farm) => farm.id === farmId)?.isAssetsWriteOffEnabled,
    [farmId, allFarms]
  );

  return isAssetUsageRecordsEnabled;
};
