import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { Grid2 } from "@mui/material";

import { IChangeOwnPasswordForm } from "../../shared/interfaces/auth-form";
import { CONFIRM_PASSWORD, NEW_PASSWORD } from "../../shared/constants";
import {
  RULE_CONFIRM_PASSWORD,
  RULE_PASSWORD,
} from "src/authentication/shared/validation";
import { ControlledPasswordTextField } from "../../components/controlled-password-textfield";
import { SubmitButton } from "../../components/submit-button";
import { usePassSubmit } from "./use-pass-submit";

export const ChangeOwnPassword = (): JSX.Element => {
  const {
    handleSubmit,
    control,
    formState: { touchedFields, isValid },
    reset,
    watch,
    trigger,
  } = useForm<IChangeOwnPasswordForm>({
    mode: "onChange",
    defaultValues: { oldPassword: "", newPassword: "", confirmPassword: "" },
  });
  const { isLoading, autoHideMessage, onSubmit } = usePassSubmit(reset);

  const watchNewPassword = watch(NEW_PASSWORD);
  useEffect(() => {
    if (!touchedFields.confirmPassword) {
      return;
    }
    trigger(CONFIRM_PASSWORD);
  }, [watchNewPassword, trigger, touchedFields.confirmPassword]);

  return (
    <Grid2
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      container={true}
      spacing={2}
    >
      <DevTool control={control} />
      <ControlledPasswordTextField
        name="oldPassword"
        label="Старый пароль"
        control={control}
        rules={RULE_PASSWORD}
      />
      <ControlledPasswordTextField
        name={NEW_PASSWORD}
        label="Новый пароль"
        control={control}
        rules={RULE_PASSWORD}
      />
      <ControlledPasswordTextField
        name={CONFIRM_PASSWORD}
        label="Повторить пароль"
        control={control}
        rules={RULE_CONFIRM_PASSWORD(watchNewPassword)}
      />
      <SubmitButton
        isValid={isValid}
        isLoading={isLoading}
        autoHideMessage={autoHideMessage}
      />
    </Grid2>
  );
};
