import { ICropRotationDto } from "../dtos/crop-rotation.dto";

export class CropRotation {
  id: string;
  area: number;
  cropTypeId: string; // TODO: change to crop type name
  cropId?: string;
  productivity: number | null;
  lastSeededAt: number;
  lastHarvestedAt: number;
  year: number;

  get seededAt(): string {
    return this.millisecondsToString(this.lastSeededAt);
  }

  get harvestedAt(): string {
    return this.millisecondsToString(this.lastHarvestedAt);
  }

  get asDto(): ICropRotationDto {
    return {
      id: this.id,
      area: this.area,
      cropTypeId: this.cropTypeId,
      cropId: this.cropId,
      productivity: this.productivity,
      lastSeededAt: this.lastSeededAt,
      lastHarvestedAt: this.lastHarvestedAt,
      year: this.year,
    };
  }

  updateFromDto(dto: ICropRotationDto): void {
    this.id = dto.id;
    this.area = dto.area;
    this.cropTypeId = dto.cropTypeId;
    this.cropId = dto.cropId;
    this.productivity = dto.productivity;
    this.lastSeededAt = dto.lastSeededAt;
    this.lastHarvestedAt = dto.lastHarvestedAt;
    this.year = dto.year;
  }

  private millisecondsToString(milliseconds: number): string {
    return milliseconds
      ? new Date(milliseconds).toLocaleDateString("ru-RU", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })
      : "-";
  }
}
