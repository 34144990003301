import { stringify } from "qs";

import { IErrorResponseDto } from "../dtos/error-response.dto";
import { TOKEN_STORED_KEY } from "src/authentication/store/constants";

interface IGetUrlWithSearchProps {
  url: string;
  searchParams?: URLSearchParams;
  searchParamsObj?: TAbstractFilter;
  swaggerAuthToken?: string;
}

// eslint-disable-next-line
export type TAbstractFilter = Record<string, any>;

export abstract class BaseRestService {
  public abstract readonly ROOT_URL: string;

  async fetch3<DTO>({
    endpointPath,
    searchParams,
    swaggerAuthToken,
    provideMock,
  }: {
    endpointPath: string;
    searchParams?: TAbstractFilter;
    swaggerAuthToken?: string;
    provideMock?: (searchParams?: TAbstractFilter) => Promise<DTO>;
  }): Promise<DTO> {
    const url = [this.ROOT_URL, endpointPath].join("/");
    const urlWithSearch = this.getUrlWithSearch({
      url: url,
      searchParamsObj: searchParams,
      swaggerAuthToken: swaggerAuthToken,
    });

    let response: Response;
    if (provideMock !== undefined) {
      try {
        const jsonAlreadyParsed: DTO = await provideMock(searchParams);
        response = this.mockToResponse(jsonAlreadyParsed);
      } catch (error) {
        throw this.emulateBackendError(
          error,
          "EXCEPTION_IN_PROVIDE_MOCK()_BaseRestService.fetch3()"
        );
      }
    } else {
      response = await fetch(urlWithSearch, { method: "GET" });
    }

    if (!response.ok) {
      const error = await response.json();
      // eslint-disable-next-line no-throw-literal
      throw error as IErrorResponseDto;
    }

    try {
      const json = await response.json(); // "not a valid JSON" sometimes
      return json as DTO;
    } catch (error) {
      throw this.emulateBackendError(
        error,
        "EXCEPTION_IN_response.json()_BaseRestService.fetch3()"
      );
    }
  }

  public getUrlWithSearch({
    url,
    searchParamsObj = {},
    swaggerAuthToken,
    searchParams = new URLSearchParams(),
  }: IGetUrlWithSearchProps): string {
    const access_token = swaggerAuthToken || localStorage.getItem(TOKEN_STORED_KEY) || "";
    searchParams.append("access_token", access_token);

    const mergedParams = Array.from(searchParams.entries()).reduce(
      (acc, [key, value]) => {
        if (key in acc) {
          if (!Array.isArray(acc[key])) {
            acc[key] = [acc[key]];
          }

          acc[key].push(value);
        } else {
          acc[key] = value;
        }
        return acc;
      },
      { ...searchParamsObj }
    );

    return `${url}?${stringify(mergedParams, { arrayFormat: "indices" })}`;
  }

  private mockToResponse<DTO>(dto: DTO): Response {
    const ret: Response = {
      // from Response
      headers: new Headers({}),
      ok: true,
      redirected: false,
      status: 200,
      statusText: "MOCK_OK",
      type: "default",
      url: "URL_UNKNOWN",
      clone: () => new Response("clone is not supported"),

      // from Body
      body: null,
      bodyUsed: false,
      arrayBuffer: () => Promise.resolve(new ArrayBuffer(0)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      blob: () => Promise.resolve(new Blob()),
      formData: () => Promise.resolve(new FormData()),
      // json: () => JSON.parse(body),
      json: () => Promise.resolve(dto),
      text: () => Promise.resolve("emulated-mock"),
    };
    return ret;
  }

  private emulateBackendError(error: unknown, nameCode: string): IErrorResponseDto {
    // we'll throw just like backend => toast popup will work properly
    const newError: IErrorResponseDto = {
      error: {
        statusCode: 599,
        name: nameCode,
        message: `${error}`,
        code: nameCode,
        stack: "GeoJsonService.getMapData() => fetch3()",
      },
    };
    return newError;
  }
}
