import { AuthRole } from "src/authentication/shared/enums/auth-role";

export enum IconsEnum {
  MapIcon,
  TractorIcon,
  AssetUsageRecordsIcon,
  NaturePeopleIcon,
  ImportContactsIcon,
  SettingsIcon,
}

export type TMenuLink = {
  roles: AuthRole[];
  link: string;
  icon: IconsEnum;
  primaryText: string;
};
