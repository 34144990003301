import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import { useAppDispatch } from "../../../../store";
import { AlertPopup } from "../../components/alert-popup/alert-popup";
import { AlertWrapper } from "../../components/alert-wrapper/alert-wrapper";
import { ConfirmDialog } from "../../components/confirm-dialog/confirm-dialog";
import {
  getAlertNotifications,
  getConfirmNotification,
  getSnackNotifications,
} from "../../store/notifications.selector";
import { removeNotificationAction } from "../../store/notifications.slice";

export const NotificationsArea = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const confirm = useSelector(getConfirmNotification);
  const alerts = useSelector(getAlertNotifications);
  const snacks = useSelector(getSnackNotifications);

  // snacks handling we forward to notistack library
  useEffect(() => {
    for (let i = 0, j = 0; i < snacks.length || j < alerts.length; i++, j++) {
      const snack = snacks[i];
      const alert = alerts[j];
      setTimeout(() => {
        if (snack) {
          enqueueSnackbar(snack.message, {
            variant: snack.severity,
          });
          dispatch(removeNotificationAction(snack));
        }

        if (alert) {
          dispatch(removeNotificationAction(alert));
        }
      }, 4000);
    }
  }, [snacks, alerts, dispatch]);

  return (
    <>
      {/* notistack initialization */}
      <SnackbarProvider maxSnack={3} />

      {confirm && (
        <ConfirmDialog
          title={confirm.title}
          message={confirm.message}
          onSuccess={confirm.cb}
          severity={confirm.severity}
          onClose={() => dispatch(removeNotificationAction(confirm))}
        />
      )}

      <AlertWrapper>
        {alerts.map((item, index) => (
          <AlertPopup
            key={`alert-popup-${index}`}
            title={item.title}
            message={item.message}
            severity={item.severity}
            onClose={() => dispatch(removeNotificationAction(item))}
          />
        ))}
      </AlertWrapper>
    </>
  );
};
