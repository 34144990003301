import {
  Components,
  checkboxClasses,
  tabClasses,
  tableRowClasses,
  tabsClasses,
} from "@mui/material";

// TODO: replace constant colours to ~ blue[400] from theme
import { SELECTED_BLUE_COLOR, THEME_PRIMARY_MAIN_COLOR } from "./mui-theme-constants";

export const components: Components = {
  MuiCheckbox: {
    styleOverrides: {
      colorSecondary: {
        [`&${checkboxClasses.checked}`]: {
          color: SELECTED_BLUE_COLOR,
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paperAnchorDockedLeft: {
        borderRight: 0,
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      marginNormal: {
        marginTop: 0,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: "#f44336",
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: "45px",
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: "#707070",
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        background: "#f7f9fc",
        // minHeight: 300, TODO: find out why minHeight must be 300px

        "&.is-ecocrop": {
          [`& .${tabsClasses.indicator}`]: {
            display: "none",
          },

          [`& .${tabClasses.textColorPrimary}`]: {
            alignItems: "start",
            borderWidth: 0,
            borderLeft: "4px solid #a3a3a3",
            borderBottom: "1px solid #f7f9fc",
            [`&.${tabClasses.selected}`]: {
              borderWidth: "0 0 0 4px",
              borderLeftColor: THEME_PRIMARY_MAIN_COLOR,
              backgroundColor: "rgba(112, 157, 75, 0.20)",
            },
          },
        },
      },

      flexContainer: {
        background: "#ffffff",
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      // textColorInherit: {
      //   alignItems: "start",
      //   borderWidth: 0,
      //   borderLeft: "4px solid #a3a3a3",
      //   borderBottom: "1px solid #f7f9fc",
      //   [`&.${tabClasses.selected}`]: {
      //     borderWidth: "0 0 0 4px",
      //     borderLeftColor: THEME_PRIMARY_MAIN_COLOR,
      //     backgroundColor: "rgba(112, 157, 75, 0.20)",
      //   },
      // },
      wrapped: {
        alignItems: "start",
        textAlign: "start",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        height: "48px",
        borderBottom: "1px solid #a3a3a3",
      },
      sizeSmall: {
        "&:last-child": {
          paddingRight: 0,
          padding: "2px",
        },
      },
      stickyHeader: {
        backgroundColor: "#fff",
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        [`&${tableRowClasses.hover}`]: {
          "&:hover": {
            backgroundColor: "rgba(112, 157, 75, 0.1);",
          },
        },
        [`&${tableRowClasses.selected}`]: {
          "&$selected": {
            backgroundColor: "rgba(112, 157, 75, 0.20)",
          },
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        maxWidth: "fit-content",
        [`& pre`]: {
          fontFamily: "inherit",
          fontSize: 15,
          fontWeight: "bolder",
          lineHeight: "normal",
        },
      },
    },
  },
};
