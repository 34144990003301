import { PropsWithChildren, useMemo } from "react";
import { useSelector } from "react-redux";

import { Role } from "../../../shared/enums/role";
import { getUser } from "../../store/auth.selector";

export const Permissions = (
  props: PropsWithChildren<{ some?: Role[]; every?: Role[] }>
): JSX.Element => {
  const { children, some = false, every = false } = props;
  const user = useSelector(getUser);
  const roles = useMemo(() => (user ? Object.keys(user.roles) : []), [user]) as Role[];

  if (some) {
    return roles.some((value) => some.includes(value)) ? <>{children}</> : <></>;
  }

  // TODO: implement every logic when user can use multiple roles
  if (every) {
    return <></>;
  }

  return <></>;
};
