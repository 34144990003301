import { IMateriallyAccountableEmployeeDto } from "../dtos/materially-accountable-employee.dto";

export class MateriallyAccountableEmployee {
  readonly id: string;
  name: string;

  constructor(id: string) {
    this.id = id;
  }

  get asDto(): Partial<IMateriallyAccountableEmployeeDto> {
    return {
      id: this.id,
      name: this.name,
    };
  }

  updateFromDto(dto: IMateriallyAccountableEmployeeDto): void {
    this.name = dto.name;
  }
}
