import { Box, SxProps, Theme } from "@mui/material";
import React, { PropsWithChildren } from "react";

export function ScreenCenter({
  minHeight = `90vh`,
  sx,
  children,
}: PropsWithChildren<{
  minHeight?: string;
  sx?: SxProps<Theme>;
}>): JSX.Element {
  return (
    <Box
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      width={"100%"}
      minHeight={minHeight}
      fontSize={20}
      sx={sx}
    >
      {children}
    </Box>
  );
}
