import React, { Suspense, lazy, useCallback } from "react";
import { useSelector } from "react-redux";

import { CatalogTabLayout } from "../../../../pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout";
import { useAppDispatch } from "../../../../store";
import {
  deletePhenophasesAction,
  fetchPhenophasesAction,
  getSelectedIds,
  setSearchText,
  setSelectedIdsAction,
} from "../../store";
import { addNotificationAction } from "../../../notifications";
import { NotificationType } from "../../../notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../notifications/shared/enums/notification-severity";
import { CATALOG_SECTIONS_ENUM } from "src/pages/catalogs/shared/components/catalog-tab-layout/catalog-tab-layout-types";

const PhenophaseList = lazy(() => import("../phenophases-list/phenophases-list"));

const PhenophasesTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedIds = useSelector(getSelectedIds);

  const onDelete = useCallback(
    () =>
      dispatch(deletePhenophasesAction(selectedIds))
        .then(() => {
          dispatch(
            addNotificationAction({
              type: NotificationType.Snack,
              severity: NotificationSeverity.Success,
              message: "Запись удалена успешно",
            })
          );
          dispatch(fetchPhenophasesAction());
        })
        .catch((e) =>
          dispatch(
            addNotificationAction({
              type: NotificationType.Snack,
              severity: NotificationSeverity.Error,
              message: "Произошла ошибка при удалении записи",
              error: e,
            })
          )
        ),
    [selectedIds, dispatch]
  );

  const onCancel = useCallback(() => dispatch(setSelectedIdsAction([])), [dispatch]);
  const onSearch = useCallback(
    (value: string) => dispatch(setSearchText(value)),
    [dispatch]
  );

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <CatalogTabLayout
        onDelete={onDelete}
        type={CATALOG_SECTIONS_ENUM.PHENOPHASE}
        selectedCount={selectedIds.length}
        onCancel={onCancel}
        onSearch={onSearch}
      >
        <PhenophaseList />
      </CatalogTabLayout>
    </Suspense>
  );
};

export default PhenophasesTab;
