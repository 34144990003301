import { FilterName } from "../enums/filter-name";

type FilterValue = null | number | string | number[] | string[];

export type TDateInFilter = number | null;

export interface IFilters extends Record<FilterName, FilterValue> {
  [FilterName.FarmId]: string | null;
  [FilterName.FarmName]: string | null;
  [FilterName.SeasonId]: string | null;
  [FilterName.Season]: string | null;
  [FilterName.ArcgisFarmName]: string | null;
  [FilterName.StartDate]: TDateInFilter;
  [FilterName.EndDate]: TDateInFilter;
}

export type IFilterLoadings = Record<FilterName, boolean>;

export type IFilterDisabling = Record<FilterName, boolean>;

export interface IFiltersState {
  init: boolean;
  filters: IFilters;
  loading: IFilterLoadings;
  disabling: IFilterDisabling;
}

export const emptyFilters: IFilters = {
  [FilterName.Custom]: null,
  [FilterName.FarmId]: null,
  [FilterName.FarmName]: null,
  [FilterName.SeasonId]: null,
  [FilterName.Season]: null,
  [FilterName.ArcgisFarmName]: null,
  [FilterName.StartDate]: null,
  [FilterName.EndDate]: null,
};
