import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export function AssetUsageRecordsIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon {...props} viewBox="0 0 36 36">
      <path d="M28.5 10.5H13.5C11.85 10.5 10.5 11.85 10.5 13.5V28.5C10.5 30.15 11.85 31.5 13.5 31.5H28.5C30.15 31.5 31.5 30.15 31.5 28.5V13.5C31.5 11.85 30.15 10.5 28.5 10.5ZM28.5 13.5V16.5H13.5V13.5H28.5ZM19.5 22.5V19.5H22.5V22.5H19.5ZM22.5 25.5V28.5H19.5V25.5H22.5ZM16.5 22.5H13.5V19.5H16.5V22.5ZM25.5 19.5H28.5V22.5H25.5V19.5ZM13.5 25.5H16.5V28.5H13.5V25.5ZM25.5 28.5V25.5H28.5V28.5H25.5ZM9 25.5H7.5C5.85 25.5 4.5 24.15 4.5 22.5V7.5C4.5 5.85 5.85 4.5 7.5 4.5H22.5C24.15 4.5 25.5 5.85 25.5 7.5V9H22.5V7.5H7.5V22.5H9V25.5Z" />
    </SvgIcon>
  );
}
