import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { RootState } from "../../../store-types";
import { IMapPageState } from "../shared/interfaces/map-page-state";
import { MAP_PAGE_MODULE_NAME } from "./constants";

export const getMapPageState = (appState: RootState): IMapPageState =>
  appState[MAP_PAGE_MODULE_NAME];

export const getIsLoading = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.isLoading
);

export const getFarmNameTuples = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.farmNameTuples
);

export const getSelectedCropNames = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.selectedCropNames
);

export const getZoom = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.zoom
);

export const getCenter = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.center
);

export const getBounds = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.bounds
);

export const getGeoJson = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.geoJson
);

export const getFarmLandData = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.farmLandData
);

export const getFarmFeaturesData = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.farmFeatures
);

export const getHighlightedFarmLandId = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.highlightedFarmLandId
);

export const getIsDrawerOpen = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.isDrawerOpen
);

export const getIsLoadingGeoJson = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.isLoadingGeoJson
);

export const getGeoJsonAlreadyFetchedForFarmSeason = createDraftSafeSelector(
  getMapPageState,
  (state: IMapPageState) => state.geoJsonAlreadyFetchedForFarmSeason
);
